import { ROUTE_NAME } from '../constants';

import type { RouteRecord } from '../types';

const workflowsRoutes: RouteRecord[] = [
  {
    path: '/workflows/:pathMatch(.*)*', // all paths starting with /workflows
    name: ROUTE_NAME.WORKFLOWS_OVERVIEW,
    component: () => {
      return import('@/components/Workflows/Pages/Overview.vue');
    },
  },
];

export default workflowsRoutes;
