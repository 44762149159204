import { PERMISSION } from '@/Configs/Constants';
import integrationHub from '@/routes/integrationHub';

import accountSettings from './account_settings';
import autoReplies from './auto_replies';
import balanceSettings from './balance_settings';
import businessHours from './business_hours';
import channels from './channels';
import chatbots from './chatbots';
import contactGroups from './contact_groups';
import csat from './csat';
import customFields from './custom_fields';
import customViews from './custom_views';
import developers from './developers';
import emailTheme from './email_theme';
import flowBuilder from './flowbuilder';
import ivr from './ivr';
import labels from './labels';
import locations from './locations';
import quickReplies from './quick_replies';
import rules from './rules';
import subscriptionSettings from './subscription_settings';
import teams from './teams';
import ticketResults from './ticket_results';
import transactions from './transactions';
import usageOverview from './usage_overview';
import users from './users';
import waTemplates from './wa_templates';
import webWidgets from './web_widgets';
import widgetAutomation from './widget_automation';
import settingsInbox from '../inbox';

import type { RouteRecord } from '../types';

const adminRoutes: RouteRecord[] = [
  {
    path: '/admin',
    component: require('../../components/Admin').default,
    meta: {
      admin: true,
    },
    children: [
      {
        path: '',
        component: require('../../components/OverviewPage/Pages/OverviewPage').default,
      },
      {
        path: '/admin/get-started',
        component: require('../../components/GetStarted/pages').default,
        meta: { permissionRequired: PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE },
      },
      ...chatbots,
      ...rules,
      ...labels,
      ...teams,
      ...users,
      ...users,
      ...ticketResults,
      ...contactGroups,
      ...channels,
      ...customFields,
      ...quickReplies,
      ...autoReplies,
      ...webWidgets,
      ...developers,
      ...customViews,
      ...ivr,
      ...emailTheme,
      ...flowBuilder,
      ...emailTheme,
      ...waTemplates,
      ...widgetAutomation,
      ...csat,
      ...businessHours,
      ...locations,
      ...accountSettings,
      ...subscriptionSettings,
      ...balanceSettings,
      ...transactions,
      ...integrationHub,
      ...usageOverview,
      ...settingsInbox,
    ],
  },
  { path: '*', component: require('../../components/PageNotFound').default },
];

export default adminRoutes;
