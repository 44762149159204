<template>
  <div class="ml-[120px] mt-20 flex h-full w-full flex-col">
    <t-feature-icon :icon="FEATURE_ICON.HELPMATE" />

    <p class="t-text-h1 mt-8">{{ $t('ai_chatbot.placeholder') }}</p>

    <div class="mb-8 flex w-fit items-center justify-center rounded-3xl bg-purple-200 px-4 py-2">
      <p class="mb-0 text-sm font-semibold text-purple-800">{{ $t('ai_chatbot.beta_under_construction') }}</p>
    </div>

    <div class="max-w-[582px]">
      <p class="t-text-md text-grey-700" v-html="$t('ai_chatbot.landing_paragraph_1')"></p>
      <p class="t-text-md text-grey-700" v-html="$t('ai_chatbot.landing_paragraph_2')"></p>
    </div>

    <div class="mt-8 flex items-center justify-start">
      <a target="_blank" class="cursor-pointer" href="https://ai.trengo.com/docs/guide.pdf">
        <t-button btn-style="secondary" class="cursor-pointer" size="lg">{{ $t('ai_chatbot.learn_more') }}</t-button>
      </a>
    </div>
  </div>
</template>

<script lang="ts" setup>
import TFeatureIcon from '@/components/common/TFeatureIcon';
import { FEATURE_ICON } from '@/Configs/Constants';
</script>
