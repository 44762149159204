<template>
  <div class="ml-[120px] mt-20 flex h-full w-full flex-col">
    <t-feature-icon :icon="FEATURE_ICON.HELPMATE" />

    <p class="t-text-h1 mt-8">{{ $t('ai_chatbot.placeholder') }}</p>

    <div class="mb-8 flex w-fit items-center justify-center rounded-3xl bg-purple-200 px-4 py-2">
      <p class="mb-0 text-sm font-semibold text-purple-800">{{ $t('ai_chatbot.power_up') }}</p>
    </div>

    <div v-for="item in listItems" :key="item.id" class="mb-8 flex items-start justify-start">
      <div class="mr-3 flex h-6 w-6 items-center rounded-3xl bg-grey-800 p-1">
        <check-linear size="1rem" fill="white" />
      </div>

      <p class="t-text-md mb-0">{{ item.text }}</p>
    </div>

    <t-input-checkbox :model-value="terms" name="terms_and_conditions" @change="terms = !terms">
      <span class="cursor-pointer" v-html="$t('ai_chatbot.accept_terms_and_conditions')"></span>
    </t-input-checkbox>

    <div class="mt-8 flex items-center justify-start">
      <t-button class="mr-4" :disabled="!terms" btn-style="primary" size="lg" @click="emit('register')">
        {{ $t('ai_chatbot.start_using_ai') }}
      </t-button>
      <a target="_blank" class="cursor-pointer" href="https://help.trengo.com/category/trengo-ai">
        <t-button btn-style="secondary" class="cursor-pointer" size="lg">{{ $t('ai_chatbot.learn_more') }}</t-button>
      </a>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { CheckLinear } from '@trengo/trengo-icons';
import { ref } from 'vue';

import { vm } from '@/ApplicationHandler';
import TFeatureIcon from '@/components/common/TFeatureIcon';
import { FEATURE_ICON } from '@/Configs/Constants';

const emit = defineEmits(['register']);

const terms = ref(false);

const listItems = [
  { id: 1, text: vm.$tc('ai_chatbot.terms_and_conditions_item_1') },
  { id: 2, text: vm.$tc('ai_chatbot.terms_and_conditions_item_2') },
  { id: 3, text: vm.$tc('ai_chatbot.terms_and_conditions_item_3') },
];
</script>
