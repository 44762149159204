import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "block h-screen overflow-y-scroll md:h-full" }
const _hoisted_2 = { class: "mx-4 flex flex-col items-start px-0 pb-40 pt-20 leading-none md:px-2 md:pb-20 lg:mx-20" }
const _hoisted_3 = {
  key: 1,
  class: "mb-8"
}
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "settings-landing-content" }
const _hoisted_6 = {
  key: 0,
  class: "mb-3 max-w-full text-4xl font-bold text-grey-800 xl:max-w-md"
}
const _hoisted_7 = {
  key: 1,
  class: "inline-flex rounded-full bg-grey-300 px-4 py-2 text-center text-sm font-semibold text-grey-700"
}
const _hoisted_8 = {
  key: 2,
  class: "mt-4 max-w-full xl:max-w-md"
}
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "mt-8 flex w-full flex-col md:w-auto xl:flex-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_feature_icon = _resolveComponent("t-feature-icon")!
  const _component_t_unordered_list = _resolveComponent("t-unordered-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isFeatureIcon)
        ? (_openBlock(), _createBlock(_component_t_feature_icon, {
            key: 0,
            icon: _ctx.icon as FeatureIcon,
            class: "mb-8"
          }, null, 8 /* PROPS */, ["icon"]))
        : (_ctx.icon)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("img", {
                src: _ctx.icon,
                alt: _ctx.title,
                class: "h-88px w-88px"
              }, null, 8 /* PROPS */, _hoisted_4)
            ]))
          : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.title)
          ? (_openBlock(), _createElementBlock("h1", _hoisted_6, _toDisplayString(_ctx.title), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        (_ctx.badge)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("span", null, _toDisplayString(_ctx.badge), 1 /* TEXT */)
            ]))
          : _createCommentVNode("v-if", true),
        _renderSlot(_ctx.$slots, "entitlement-badge"),
        (_ctx.intro)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("p", {
                class: "text-base font-medium text-grey-700",
                innerHTML: _ctx.intro
              }, null, 8 /* PROPS */, _hoisted_9)
            ]))
          : _createCommentVNode("v-if", true),
        (_ctx.list)
          ? (_openBlock(), _createBlock(_component_t_unordered_list, {
              key: 3,
              items: _ctx.list,
              class: "mt-8 max-w-full xl:max-w-md",
              size: "lg"
            }, null, 8 /* PROPS */, ["items"]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("section", _hoisted_10, [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _renderSlot(_ctx.$slots, "hint")
      ])
    ])
  ]))
}