export const ROUTE_NAME = {
  AI_BASE: 'aiBase',
  COMPOSER_POPOUT: 'composer-popout',
  CONTACT_GROUPS_DETAIL: 'contact-groups-detail',
  CONTACT_GROUPS_OVERVIEW: 'contact-groups-overview',
  CSAT_LANDING: 'csatLanding',
  HOME: 'home',
  INTEGRATIONS: 'integrations',
  INTEGRATIONS_LEGACY_PLUGINS: 'plugins',
  INTEGRATIONS_LEGACY_PLUGINS_CREATE: 'createPlugins',
  INTEGRATIONS_LEGACY_PLUGINS_CREATE_TYPE: 'createPluginsType',
  INTEGRATIONS_LEGACY_PLUGINS_EDIT_ITEM: 'editPluginsItem',
  INTEGRATIONS_LEGACY_REST: 'rest',
  INTEGRATIONS_LEGACY_WEBHOOKS: 'webhooks',
  INTEGRATIONS_LEGACY_WEBHOOK_CREATE: 'webhookCreate',
  INTEGRATIONS_LEGACY_WEBHOOK_ITEM: 'webhookItem',
  INTEGRATIONS_LEGACY_ZAPIER: 'zapier',
  LOGIN: 'login',
  REPORTING_BASE: 'reporingBase',
  REPORTING_LEGACY_DASHBOARD: 'reportingDashboardOld',
  REQUEST_EMAIL: 'request-email',
  RESET_PASSWORD: 'reset-password',
  SETTINGS_INBOX: 'inbox',
  SETUP_2FA: 'setup-2fa',
  SETUP_PASSWORD: 'auth-password',
  SUBSCRIPTION: 'subscription',
  TICKETS: 'tickets',
  UNAUTHORIZED: 'unauthorized',
  VERIFICATION: 'verification',
  WORKFLOWS_OVERVIEW: 'workflows.overview',
} as const;
