<script lang="ts" setup>
import { ArrowTopCircleLinear, ArrowTopRightLinear, Box2Linear, InfoLinear } from '@trengo/trengo-icons';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { PLANS_USAGE_BASED } from '@/api/mocks/chargebee';
import { openChargebeeCheckout } from '@/api/modules/subscriptionChargebee';
import AccountPagePanel from '@/components/common/AccountPagePanel/Index.vue';
import { PLAN_PRICE_ID } from '@/Configs/Constants';
import { useSubscriptionStore } from '@/store/pinia';

import SubscriptionPlan from './SubscriptionPlan.vue';

import type { BillingFrequency, ChargebeePlan, PlanId, PlanPriceId } from '@/types';

const { t } = useI18n();
const { subscription } = useSubscriptionStore();
const plans = ref<ChargebeePlan[]>([]);
const selectedFrequency = ref<BillingFrequency>('annually');
const isSubscriptionCreationSuccessful = ref(false);
const cbInstance = window.Chargebee.init({
  site: window.CHARGEBEE_SITE,
  publishableKey: window.CHARGEBEE_PUBLISHABLE_KEY,
});

const loadPlans = () => {
  plans.value = PLANS_USAGE_BASED;
};

const changeFrequency = (activeSegment: { index: number; segment: any }) => {
  selectedFrequency.value = activeSegment.segment.value;
};

onMounted(() => {
  loadPlans();
});

const generateNewCheckout = (priceId: PlanPriceId) =>
  openChargebeeCheckout(priceId)
    .then((res) => res.data)
    .catch((err) => {
      console.error(err);
    });

async function handleOpenCheckout(priceId: PlanPriceId) {
  cbInstance.openCheckout({
    hostedPage: () => generateNewCheckout(priceId),
    loaded() {
      console.log('checkout opened');
    },
    close() {
      /* this.loading = false; */
      console.log('checkout closed');
      console.log(isSubscriptionCreationSuccessful);
      if (isSubscriptionCreationSuccessful.value) {
        window.location.reload();
      }
    },
    success(hostedPageId: string) {
      console.log({ hostedPageId });
      isSubscriptionCreationSuccessful.value = true;
      // Hosted page id will be unique token for the checkout that happened
      // You can pass this hosted page id to your backend
      // and then call our retrieve hosted page api to get subscription details
      // https://apidocs.chargebee.com/docs/api/hosted_pages#retrieve_a_hosted_page
    },
    step(value: unknown) {
      // value -> which step in checkout
      console.log(value);
    },
    error(err: unknown) {
      /* this.errorMsg = error; */
      console.error(err);
    },
  });
}

const handlePlanClick = (planId: PlanId, frequency: BillingFrequency) => {
  const planIdPart = planId.toUpperCase() as Uppercase<PlanId>;
  const frequencyPart = frequency.toUpperCase() as Uppercase<typeof frequency>;
  const priceId = PLAN_PRICE_ID[`USAGE_BASED__${planIdPart}__${frequencyPart}`];
  handleOpenCheckout(priceId);
};
</script>

<template>
  <account-page-panel title="Choose the right plan for you">
    <template #headingRight>
      <div class="w-[300px]">
        <t-segmented-control
          size="sm"
          :active-index="1"
          :segments="[
            { label: t('subscription.plan_selector__monthly'), value: 'monthly' },
            { label: t('subscription.plan_selector__annually'), value: 'annually' },
          ]"
          @change="changeFrequency"
        />
      </div>
    </template>
    <div class="flex flex-col gap-4">
      <div
        v-if="subscription.isInTrial"
        class="flex items-center justify-between gap-3 rounded-xl border-1 border-grey-300 bg-white p-4"
      >
        <t-thumbnail class="bg-white">
          <box2-linear size="1rem" />
        </t-thumbnail>
        <div class="flex-grow">
          <div class="text-md font-semibold text-grey-800">{{ t('subscription.plan_selector__title') }}</div>
          <div class="text-sm text-grey-700">
            {{ t('subscription.plan_selector__subtitle', { date: subscription.trialExpirationDate }) }}
          </div>
        </div>
        <div>
          <t-badge
            v-if="subscription.trialCountdown >= 0"
            variant="upgrade"
            :text="
              subscription.trialCountdown === 1
                ? t('subscription.plan_selector__one_day_left')
                : t('subscription.plan_selector__days_left', { days: subscription.trialCountdown })
            "
          >
            <template #icon><arrow-top-circle-linear size="1rem" /></template>
          </t-badge>
        </div>
      </div>
      <div class="flex gap-4">
        <subscription-plan
          v-for="plan in plans"
          :key="plan.title"
          :plan="plan"
          :selected-frequency="selectedFrequency"
          @click="(type, frequency) => handlePlanClick(type, frequency)"
        />
      </div>
      <t-inline-banner>
        <template #icon>
          <info-linear />
        </template>
        <div class="text-grey-900">{{ t('subscription.plan_selector__more_detailed_list_per_plan') }}</div>
        <template #action>
          <t-button size="sm" btn-style="secondary" href="https://trengo.com/prices" target="__blank">
            {{ t('subscription.plan_selector__view_all_functionalities') }}
            <arrow-top-right-linear size="1.125rem" class="ml-1 inline" />
          </t-button>
        </template>
      </t-inline-banner>
    </div>
  </account-page-panel>
</template>
