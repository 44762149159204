export const WhatsappProviders = {
  MESSAGEBIRD: {
    id: 'messagebird',
    name: 'MessageBird',
  },
  TWILIO: {
    id: 'twilio',
    name: 'Twilio',
  },
  CM: {
    id: 'cm',
    name: 'CM',
  },
  SANDBOX: {
    id: 'sandbox',
    name: 'Sandbox',
  },
  TRENGO: {
    id: 'trengo',
    name: 'Trengo',
  },
  TRENGO_BSP: {
    id: 'trengo_bsp',
    name: 'Trengo BSP',
  },
  THREE_SIXTY_DIALOG: {
    id: '360dialog',
    name: '360dialog',
  },
} as const;

export const TEMPLATE_HEADER_FILE_LIMIT_IN_BYTES = 15000000;

export const WA_TEMPLATE_COMPONENT = {
  buttons: 'BUTTONS',
  header: 'HEADER',
  footer: 'FOOTER',
} as const;

export const TEMPLATE_FIELDS = {
  body: 'body',
  buttons: 'buttons',
  header: 'header',
  footer: 'footer',
  category: 'category',
  language: 'language',
  channel: 'channel',
  name: 'name',
} as const;

export const TEMPLATE_STATUS = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  DECLINED: 'DECLINED',
} as const;

export const TEMPLATE_ACTION = {
  CREATE: 'create',
  EDIT: 'edit',
} as const;

export const TEMPLATE_HEADER_TYPE = {
  TEXT: 'text',
  IMAGE: 'image',
} as const;

export const TEMPLATE_FORM_CONTENTS = {
  HEADER: 'header',
  FOOTER: 'footer',
  HEADER_MULTI_CHOICE_CARDS: 'header-cards',
  HEADER_BANNER: 'header-banner',
} as const;

export const WHATSAPP_GRAPH_API_VERSION = 'v19.0';

export const TEMPLATE_PARAM_TYPE = {
  BODY: 'body',
  HEADER: 'header',
} as const;

export const WHATSAPP_BUSINESS_MESSAGE_TYPE = {
  SESSION_MESSAGE: 'session_message',
  TEMPLATE_MESSAGE: 'template_message',
} as const;
