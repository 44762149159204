<template>
  <span class="my-auto ml-1 mt-2 flex flex-shrink-0 flex-row flex-nowrap lg:m-0">
    <check-linear v-if="status === 'SENT'" class="ml-1 text-black" />
    <double-check-linear v-if="status === 'DELIVERED'" size="1.4rem" />
    <double-check-linear v-if="status === 'READ'" class="text-leaf-500" size="1.4rem" />
    <p v-if="status === 'FAILED'" class="m-0 ml-1 p-0">- {{ $tc('tickets.failed_to_deliver') }}</p>
  </span>
</template>

<script setup lang="ts">
import { DoubleCheckLinear, CheckLinear } from '@trengo/trengo-icons';

defineProps<{ status: 'SENT' | 'DELIVERED' | 'FAILED' | 'READ' }>();
</script>
