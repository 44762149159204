<template>
  <integration-loading v-if="isLoading" class="mt-4" />

  <t-inline-banner v-else-if="this.hasLoaded && this.hasError" type="error" class="mt-4">
    <template #icon>
      <cross-octagon-linear />
    </template>

    <p class="t-text-sm-emphasize m-0 p-0">
      {{ $t('integration_hub.sidebar_error_title') }}
    </p>
    <p class="t-text-sm m-0 p-0">
      {{ $t('integration_hub.sidebar_error_description') }}
    </p>
  </t-inline-banner>

  <div v-else-if="this.hasLoaded && opportunities.length === 0" class="mt-4">
    <t-inline-banner type="default">
      <template #icon>
        <info-linear />
      </template>

      <p class="t-text-sm-emphasize m-0 p-0">
        {{ $t('integration_hub.sidebar_no_data_found', { name: this.installation.name }) }}
      </p>
    </t-inline-banner>
  </div>

  <div v-else>
    <div
      v-for="(opportunity, i) in opportunities"
      :key="i"
      class="mb-4 rounded-lg border border-grey-300 bg-white px-2"
    >
      <div v-if="opportunity.title">
        <a
          v-if="opportunity.opportunity_url"
          class="mb-2 cursor-pointer pt-3 text-base font-semibold text-leaf-500"
          :href="opportunity.opportunity_url"
          target="_blank"
        >
          {{ opportunity.title }}
        </a>
        <p v-else class="mb-2 pt-3 text-base font-semibold">
          {{ opportunity.title }}
        </p>
      </div>

      <div v-if="opportunity.monetary_amount">
        <span class="text-xs text-grey-600">{{ $t('integration_hub.sidebar_crm_amount') }}</span>
        <p class="mb-2 text-sm font-semibold">{{ opportunity.currency }} {{ opportunity.monetary_amount }}</p>
      </div>

      <div v-if="opportunity.close_date">
        <span class="text-xs text-grey-600">{{ $t('integration_hub.sidebar_crm_expected_close_date') }}</span>
        <p class="mb-2 text-sm font-semibold">{{ formatDate(opportunity.close_date) }}</p>
      </div>

      <div v-if="opportunity.pipeline.name">
        <span class="text-xs text-grey-600">{{ $t('integration_hub.sidebar_crm_pipeline') }}</span>
        <p class="mb-2 text-sm font-semibold">{{ opportunity.pipeline.name }}</p>
      </div>

      <div v-if="opportunity.status">
        <span class="text-xs text-grey-600">{{ $t('integration_hub.sidebar_crm_stage') }}</span>
        <p class="mb-2 text-sm font-semibold">{{ opportunity.status }}</p>
      </div>

      <div v-if="opportunity.type">
        <span class="text-xs text-grey-600">{{ $t('integration_hub.sidebar_crm_type') }}</span>
        <p class="mb-2 text-sm font-semibold">{{ opportunity.type }}</p>
      </div>

      <t-divider v-if="i !== Object.keys(opportunities).length - 1" />
    </div>
  </div>
</template>

<script lang="ts">
import { InfoLinear, CrossOctagonLinear } from '@trengo/trengo-icons';
import moment from 'moment';
import { defineComponent } from 'vue';

import { fetchCrmOpportunities } from '@/api';

import IntegrationLoading from './IntegrationLoading.vue';

import type { CrmOpportunity } from '@/api';
import type { InstalledIntegration } from '@/store/types/integrations';
import type Ticket from '@/types/tickets';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'IntegrationCrmOpportunities',

  components: {
    IntegrationLoading,
    InfoLinear,
    CrossOctagonLinear,
  },

  data() {
    return {
      isLoading: false,
      hasError: false,
      hasLoaded: false,
      opportunities: [] as Array<CrmOpportunity>,
    };
  },

  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    installation: {
      type: Object as PropType<InstalledIntegration>,
      default: () => ({}),
    },
    ticket: {
      type: Object as PropType<Ticket>,
      default: () => ({}),
    },
    companyId: {
      type: [Number, String],
      default: '',
    },
  },

  methods: {
    async fetchOpportunities() {
      if (this.hasLoaded || this.isLoading) {
        return;
      }
      try {
        this.isLoading = true;
        const { data: opportunities } = await fetchCrmOpportunities(this.installation.installation_id, this.companyId);
        this.opportunities = opportunities;
      } catch (e) {
        this.hasError = true;
        console.error(e);
      } finally {
        this.hasLoaded = true;
        this.isLoading = false;
      }
    },

    formatDate(date: string) {
      const tz = moment.tz(date, window.APP_TIMEZONE);
      return tz.clone().tz(this.$root.user.timezone).format('DD-MM-Y');
    },
  },

  watch: {
    selected: {
      immediate: true,
      handler(val) {
        if (val) {
          this.fetchOpportunities();
        }
      },
    },
  },
});
</script>
