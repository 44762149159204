import { AnalyticsBrowser } from '@segment/analytics-next';

import { useAuthStore, useUserStore } from '@/store/pinia';

import { DATA_TRACKING_APP_ACCESS_MEDIUM, EVENT_NAME } from './constants';
import { isReportsRouteWithFilter, isTicketRoute, isAiSubroute } from '../routeHelpers';
import { getDeviceTypeForTracking } from './helpers';
import { isDesktopApp } from '../GlobalHelpers';

import type { PageTrackingParams, SegmentTrackSettingsPageParams, TrackEventParams } from './types';
import type { User } from '@/types/common';

export const segmentAnalytics = new AnalyticsBrowser();

export const initializeSegmentAnalytics = (): void => {
  segmentAnalytics.load({ writeKey: window.SEGMENT_WRITE_KEY });
};

export const identifySegmentUser = (user: User): void => {
  // @ts-expect-error: TypeScript might not recognize `segmentAnalytics.identify` method
  segmentAnalytics.identify(user?.id, {
    email: user?.email,
    name: user?.full_name,
    variation: 'A',
    user_role_id: user?.role?.id,
    context_group_id: user?.agency_id,
    device_category: getDeviceTypeForTracking(),
    traffic_medium: isDesktopApp ? DATA_TRACKING_APP_ACCESS_MEDIUM.APP : DATA_TRACKING_APP_ACCESS_MEDIUM.BROWSER,
  });
};

export async function awaitUserDataAndTrack() {
  const userStore = useUserStore();
  await useAuthStore().init();
  await userStore.init();
  if (userStore.user) {
    identifySegmentUser(userStore.user);
  }
}

export const segmentTrackEvent = ({ eventName, agencyId, userId, page }: TrackEventParams): void => {
  segmentAnalytics.track(eventName, {
    agencyId,
    userId,
    page,
  });
};

export const trackPageWithSegmentAnalytics = ({ user, route }: PageTrackingParams): void => {
  if (!user?.id || isTicketRoute(route) || isReportsRouteWithFilter(route) || isAiSubroute(route)) return;

  segmentAnalytics.page({
    name: 'Page',
  });
};

export const trackSettingsPageWithSegment = ({
  isSettingsPage,
  pageUrl,
  agencyId,
  userId,
  category,
  featureName,
}: SegmentTrackSettingsPageParams): void => {
  if (!isSettingsPage) return;
  segmentAnalytics.page(EVENT_NAME.SETTINGS_PAGE, {
    pageUrl,
    agencyId,
    userId,
    category,
    featureName,
  });
};
