import {
  EyeLinear,
  UserPlusLinear,
  CommentsLinear,
  ServerLinear,
  WalletPlusLinear,
  PhoneLinear,
  MegaphoneLinear,
  ShareLinear,
} from '@trengo/trengo-icons';

import { SUBSCRIPTION_ADDON_CATEGORY, SUBSCRIPTION_ADDON_CHARGEBEE_TYPE } from '@/Configs/Constants';

import type { ConversationDetails } from './components/EditSubscription/types';
import type { SubscriptionAddonDetail, SubscriptionAddonsByCategoryUsageBased } from '@/types';


export const CONVERSATION_DETAILS: ConversationDetails = {
  prices: {
    monthly: 18,
    annually: 15,
  },
  includedConversations: {
    tier0: 0,
    tier1: 100,
    tier2: 300,
    tier3: 1000,
  },
};

export const PLAN_NAME = {
  tier0: 'Trial',
  tier1: 'Essentials',
  tier2: 'Boost',
  tier3: 'Pro',
};

export const SUBSCRIPTION_ADDONS: SubscriptionAddonsByCategoryUsageBased[] = [
  {
    title: 'subscription.addon_category__additional_conversations',
    category: SUBSCRIPTION_ADDON_CATEGORY.CONVERSATIONS,
    items: [],
  },
  {
    title: 'subscription.addon_category__additional_seats',
    category: SUBSCRIPTION_ADDON_CATEGORY.SEATS,
    items: [],
  },
  {
    title: 'subscription.addon_category__carrier_costs',
    category: SUBSCRIPTION_ADDON_CATEGORY.CARRIER_COSTS,
    items: [],
  },
  {
    title: 'subscription.addon_category__general_addons',
    category: SUBSCRIPTION_ADDON_CATEGORY.ADDONS,
    items: [],
  },
];

export const SUBSCRIPTION_ADDON_DETAILS: readonly SubscriptionAddonDetail[] = [
  {
    item_chargebee_type: SUBSCRIPTION_ADDON_CHARGEBEE_TYPE.USER_SEATS,
    category: SUBSCRIPTION_ADDON_CATEGORY.SEATS,
    title: 'subscription.addon__user_seats',
    is_quantity_based: true,
    icon: UserPlusLinear,
  },
  {
    item_chargebee_type: SUBSCRIPTION_ADDON_CHARGEBEE_TYPE.OBSERVER_SEATS,
    category: SUBSCRIPTION_ADDON_CATEGORY.SEATS,
    title: 'subscription.addon__observer_seats',
    is_quantity_based: true,
    icon: EyeLinear,
  },
  {
    item_chargebee_type: SUBSCRIPTION_ADDON_CHARGEBEE_TYPE.CONVERSATION,
    category: SUBSCRIPTION_ADDON_CATEGORY.CONVERSATIONS,
    title: 'subscription.addon__conversations',
    is_quantity_based: true,
    icon: CommentsLinear,
  },
  {
    item_chargebee_type: SUBSCRIPTION_ADDON_CHARGEBEE_TYPE.VOICE_CHANNEL,
    category: SUBSCRIPTION_ADDON_CATEGORY.ADDONS,
    title: 'subscription.addon__voice_channel',
    is_quantity_based: false,
    icon: PhoneLinear,
  },
  {
    item_chargebee_type: SUBSCRIPTION_ADDON_CHARGEBEE_TYPE.BROAD_CASTING,
    category: SUBSCRIPTION_ADDON_CATEGORY.ADDONS,
    title: 'subscription.addon__broadcasting',
    is_quantity_based: false,
    icon: MegaphoneLinear,
  },
  {
    item_chargebee_type: SUBSCRIPTION_ADDON_CHARGEBEE_TYPE.FLOWBOT,
    category: SUBSCRIPTION_ADDON_CATEGORY.ADDONS,
    title: 'subscription.addon__flowbot',
    is_quantity_based: false,
    icon: ShareLinear,
  },
  {
    item_chargebee_type: SUBSCRIPTION_ADDON_CHARGEBEE_TYPE.API_HIGH_RATE_LIMIT,
    category: SUBSCRIPTION_ADDON_CATEGORY.ADDONS,
    title: 'subscription.addon__api_high_rate_limit',
    is_quantity_based: false,
    icon: WalletPlusLinear,
  },
  {
    item_chargebee_type: SUBSCRIPTION_ADDON_CHARGEBEE_TYPE.DATA_STORAGE,
    category: SUBSCRIPTION_ADDON_CATEGORY.ADDONS,
    title: 'subscription.addon__additional_data_retention',
    is_quantity_based: true,
    icon: ServerLinear,
  },
  {
    item_chargebee_type: SUBSCRIPTION_ADDON_CHARGEBEE_TYPE.CARRIER_COST_BALANCE,
    category: SUBSCRIPTION_ADDON_CATEGORY.CARRIER_COSTS,
    title: 'subscription.addon__carrier_cost_balance',
    is_quantity_based: true,
    icon: WalletPlusLinear,
  },
];

export const MULTIPLIER_EURO_25 = 2500;
