<template>
  <mobile-status v-if="isMobile" />

  <div v-else class="relative flex flex-1">
    <div v-if="showLandingPage" class="relative flex flex-1 bg-grey-200">
      <Landing />
    </div>

    <div v-else v-show="isLoaded" class="relative flex flex-1 bg-grey-200">
      <iframe v-show="showIframe" ref="ai_frame" class="absolute h-full w-full border-0" :src="AIURL" />
      <TermsAndCondition v-if="!showIframe" @register="handleRegisterUser" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { isEmpty } from 'lodash';
import { type Ref, ref, onMounted, onUnmounted, nextTick, computed, watch } from 'vue';

import { vm } from '@/ApplicationHandler';
import { FEATURE_FLAG_ACTIVATION } from '@/Configs/Constants/featureFlag';
import { USER_ROLE } from '@/Configs/Constants/UserRoles';
import { useFeatureFlagStore } from '@/store/pinia';
import breakpoints from '@/util/breakpoints';

import { registerTenant, getTenant, type Tenant } from './api';
import Landing from './Landing.vue';
import MobileStatus from './MobileStatus.vue';
import TermsAndCondition from './TermsAndCondition.vue';
import { provideToken } from './utils';

const { TRENGO, ADMINISTRATOR, SUPERVISOR } = USER_ROLE;
const isLoaded = ref(false);
const showIframe = ref(false);
const AIURL = `${window.AI_MFE_URL || process.env.AI_LOCAL_URL}?v=${Math.random()}`;
const ai_frame: Ref<HTMLIFrameElement | undefined> = ref(undefined);
const tenant: Ref<Tenant> = ref({ provider: '', provider_id: '' });
const eventInterval: Ref<number | undefined> = ref();

const showLandingPage = computed(() => {
  return useFeatureFlagStore().isEnabled(FEATURE_FLAG_ACTIVATION.AI_LANDING_PAGE);
});

const isMobile = computed(() => {
  return breakpoints.w <= 991;
});

onMounted(() => {
  mountAiDashboard();
});

const messageTargetOrigin = computed(() => {
  if (window.location.hostname === 'app-stg.intrengo.com') {
    return 'https://ai-stg.intrengo.com';
  } else if (window.APP_ENV === 'production') {
    return 'https://ai.trengo.com';
  } else {
    return process.env?.AI_LOCAL_URL || 'http://localhost:5173';
  }
});

onUnmounted(() => {
  showIframe.value = false;
  isLoaded.value = false;
  window.removeEventListener('message', authorizeIframe);

  if (eventInterval.value !== undefined) {
    clearInterval(eventInterval.value);
  }
});

function handleRegisterUser() {
  registerTenant().then((res) => {
    tenant.value = res.data;
    provideTokenToIframe();
  });
}

function getTenantUser() {
  getTenant().then((res) => {
    if (!res.data.provider_id) {
      isLoaded.value = true;
      showIframe.value = false;
      vm.$router.push({ name: 'aiDashboardTerms' });
    } else if (vm.$route.name !== 'aiDashboard') {
      vm.$router.push({ name: 'aiDashboard' });
    }
    tenant.value = res.data;
    provideTokenToIframe();
  });
}

async function provideTokenToIframe() {
  await nextTick();
  if (eventInterval.value !== undefined) {
    clearInterval(eventInterval.value);
  }
  const userSubscriptionSlug = vm?.$userPlan()?.getSubscription as { plan_slug: string };
  const isTrial = isEmpty(userSubscriptionSlug.plan_slug);
  const payload = provideToken(isTrial, tenant.value.provider_id, tenant.value.provider);
  eventInterval.value = window.setInterval(() => {
    ai_frame.value?.contentWindow?.postMessage(payload, messageTargetOrigin.value);
  }, 100);
}

function changeRoute(path: string) {
  vm.$router.push(path);
}

async function handleIframeMessage(e: MessageEvent) {
  switch (e?.data?.type) {
    case 'DATA_REQUEST':
      handleDataRequest(e);

      break;
    case 'AUTHORIZATION':
      if (e?.data?.payload === 'token') {
        ai_frame.value?.contentWindow?.postMessage(
          { type: 'AUTHORIZATION', payload: { token: localStorage.getItem('token') } },
          messageTargetOrigin.value,
        );
      }

      break;
    default:
      await authorizeIframe(e);
      break;
  }
}

function handleDataRequest(e: MessageEvent) {
  const provideDataRequest = (payload: unknown) =>
    ai_frame.value?.contentWindow?.postMessage({ type: 'DATA_REQUEST', payload }, messageTargetOrigin.value);

  switch (e?.data?.payload) {
    case 'agencyId':
      provideDataRequest({ agencyId: vm?.$store.getters['initialData/agency']?.id });
      break;
    case 'clientLocale':
      provideDataRequest({ clientLocale: vm?.$root?.user?.locale_code });
      break;
  }
}

async function authorizeIframe(e: MessageEvent) {
  if (e.data.type === 'CHANGE_ROUTE') {
    changeRoute(e.data.path);
  }
  if (!tenant.value.provider_id || e.data.type !== 'AUTH' || e.data.payload !== 'authentication') {
    return;
  }
  window.clearInterval(eventInterval.value);
  showIframe.value = true;
  await nextTick();
  isLoaded.value = true;
  vm.$router.push({ name: 'aiDashboard' });
}

function mountAiDashboard() {
  if (![TRENGO, ADMINISTRATOR, SUPERVISOR].includes(vm?.$root['user'].role.name)) {
    vm?.$router.push('/tickets');
    return false;
  }
  if (showLandingPage.value) {
    return false;
  }

  window.addEventListener('message', handleIframeMessage);

  getTenantUser();
}

watch(isMobile, (isMobile) => {
  if (!isMobile) {
    mountAiDashboard();
  }
});
</script>
