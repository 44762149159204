export const EVENT_NAME = {
  RULE_CREATED: 'Rule created',
  SETTINGS_PAGE: 'Settings page',
} as const;

export const DATA_TRACKING_DEVICE_TYPE = {
  MOBILE: 'Mobile',
  TABLET: 'Tablet',
  DESKTOP: 'Desktop',
} as const;

export const DATA_TRACKING_APP_ACCESS_MEDIUM = {
  APP: 'App',
  BROWSER: 'Browser',
} as const;
