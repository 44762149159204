import { request } from '@/util';

import type { AgentsResponses } from './utils';
import type { AxiosPromise } from 'axios';

type Sort = {
  order_by: string;
  sort_order: 'asc' | 'desc';
};

type Columns = {
  columns: string[];
};

const baseURL = 'api/v2/reporting/';

export const fetchAgents = (page: number, sort?: Sort, filters?: string): AxiosPromise<AgentsResponses> => {
  const URL = `${baseURL}agent_performance?page=${page}${filters ? '&' + filters : ''}`;
  return request(URL, 'GET');
};

export const fetchMockAgents = (): Promise<{ data: AgentsResponses }> => {
  return new Promise((resolve) => {
    const response: AgentsResponses = {
      data: [
        {
          agent: {
            full_name: 'Mohammad Mahabadi',
            profile_image: null,
            id: 1,
          },
          average_first_resolution: 1213,
          average_total_resolution: 3435,
          average_first_response: 5497,
          average_first_resolution_without_business_hours: 1216,
          average_total_resolution_without_business_hours: 3438,
          average_first_response_without_business_hours: 5500,
          total_assigned: 123,
          total_outbound: 233,
          total_closed: 201,
          total_note: 107,
        },
        {
          agent: {
            id: 2009291,
            full_name: 'Mohammad 1 Mahabadi 1',
            profile_image: null,
          },
          total_outbound: 0,
          total_note: 1,
          average_total_resolution_without_business_hours: 0,
          average_first_response: 0,
          average_first_resolution: 19737440,
          average_first_resolution_without_business_hours: 0,
          average_first_response_without_business_hours: 0,
          total_assigned: 1,
          average_total_resolution: 19737440,
          total_closed: 1,
        },
        {
          agent: {
            full_name: 'Siddhesh Deshpande',
            profile_image: null,
            id: 2,
          },
          average_first_resolution: 1214,
          average_total_resolution: 3436,
          average_first_response: 5498,
          average_first_resolution_without_business_hours: 1216,
          average_total_resolution_without_business_hours: 3438,
          average_first_response_without_business_hours: 5500,
          total_assigned: 124,
          total_outbound: 235,
          total_closed: 207,
          total_note: 109,
        },
        {
          agent: {
            full_name: 'Mohammad Mahabadi',
            profile_image: null,
            id: 1,
          },
          average_first_resolution: 1213,
          average_total_resolution: 3435,
          average_first_response: 5497,
          average_first_resolution_without_business_hours: 1216,
          average_total_resolution_without_business_hours: 3438,
          average_first_response_without_business_hours: 5500,
          total_assigned: 123,
          total_outbound: 233,
          total_closed: 201,
          total_note: 107,
        },
        {
          agent: {
            full_name: 'Siddhesh Deshpande',
            profile_image: null,
            id: 2,
          },
          average_first_resolution: 1214,
          average_total_resolution: 3436,
          average_first_response: 5498,
          average_first_resolution_without_business_hours: 1216,
          average_total_resolution_without_business_hours: 3438,
          average_first_response_without_business_hours: 5500,
          total_assigned: 124,
          total_outbound: 235,
          total_closed: 207,
          total_note: 109,
        },
        {
          agent: {
            full_name: 'Mohammad Mahabadi',
            profile_image: null,
            id: 1,
          },
          average_first_resolution: 1213,
          average_total_resolution: 3435,
          average_first_response: 5497,
          average_first_resolution_without_business_hours: 1216,
          average_total_resolution_without_business_hours: 3438,
          average_first_response_without_business_hours: 5500,
          total_assigned: 123,
          total_outbound: 233,
          total_closed: 201,
          total_note: 107,
        },
        {
          agent: {
            full_name: 'Siddhesh Deshpande',
            profile_image: null,
            id: 2,
          },
          average_first_resolution: 1214,
          average_total_resolution: 3436,
          average_first_response: 5498,
          average_first_resolution_without_business_hours: 1216,
          average_total_resolution_without_business_hours: 3438,
          average_first_response_without_business_hours: 5500,
          total_assigned: 124,
          total_outbound: 235,
          total_closed: 207,
          total_note: 109,
        },
        {
          agent: {
            full_name: 'Mohammad Mahabadi',
            profile_image: null,
            id: 1,
          },
          average_first_resolution: 1213,
          average_total_resolution: 3435,
          average_first_response: 5497,
          average_first_resolution_without_business_hours: 1216,
          average_total_resolution_without_business_hours: 3438,
          average_first_response_without_business_hours: 5500,
          total_assigned: 123,
          total_outbound: 233,
          total_closed: 201,
          total_note: 107,
        },
        {
          agent: {
            full_name: 'Siddhesh Deshpande',
            profile_image: null,
            id: 2,
          },
          average_first_resolution: 1214,
          average_total_resolution: 3436,
          average_first_response: 5498,
          average_first_resolution_without_business_hours: 1216,
          average_total_resolution_without_business_hours: 3438,
          average_first_response_without_business_hours: 5500,
          total_assigned: 124,
          total_outbound: 235,
          total_closed: 207,
          total_note: 109,
        },
        {
          agent: {
            full_name: 'Mohammad Mahabadi',
            profile_image: null,
            id: 1,
          },
          average_first_resolution: 1213,
          average_total_resolution: 3435,
          average_first_response: 5497,
          average_first_resolution_without_business_hours: 1216,
          average_total_resolution_without_business_hours: 3438,
          average_first_response_without_business_hours: 5500,
          total_assigned: 123,
          total_outbound: 233,
          total_closed: 201,
          total_note: 107,
        },
        {
          agent: {
            full_name: 'Siddhesh Deshpande',
            profile_image: null,
            id: 2,
          },
          average_first_resolution: 1214,
          average_total_resolution: 3436,
          average_first_response: 5498,
          average_first_resolution_without_business_hours: 1216,
          average_total_resolution_without_business_hours: 3438,
          average_first_response_without_business_hours: 5500,
          total_assigned: 124,
          total_outbound: 235,
          total_closed: 207,
          total_note: 109,
        },
        {
          agent: {
            full_name: 'Mohammad Mahabadi',
            profile_image: null,
            id: 1,
          },
          average_first_resolution: 1213,
          average_total_resolution: 3435,
          average_first_response: 5497,
          average_first_resolution_without_business_hours: 1216,
          average_total_resolution_without_business_hours: 3438,
          average_first_response_without_business_hours: 5500,
          total_assigned: 123,
          total_outbound: 233,
          total_closed: 201,
          total_note: 107,
        },
        {
          agent: {
            full_name: 'Siddhesh Deshpande',
            profile_image: null,
            id: 2,
          },
          average_first_resolution: 1214,
          average_total_resolution: 3436,
          average_first_response: 5498,
          average_first_resolution_without_business_hours: 1216,
          average_total_resolution_without_business_hours: 3438,
          average_first_response_without_business_hours: 5500,
          total_assigned: 124,
          total_outbound: 235,
          total_closed: 207,
          total_note: 109,
        },
        {
          agent: {
            full_name: 'Mohammad Mahabadi',
            profile_image: null,
            id: 1,
          },
          average_first_resolution: 1213,
          average_total_resolution: 3435,
          average_first_response: 5497,
          average_first_resolution_without_business_hours: 1216,
          average_total_resolution_without_business_hours: 3438,
          average_first_response_without_business_hours: 5500,
          total_assigned: 123,
          total_outbound: 233,
          total_closed: 201,
          total_note: 107,
        },
        {
          agent: {
            full_name: 'Siddhesh Deshpande',
            profile_image: null,
            id: 2,
          },
          average_first_resolution: 1214,
          average_total_resolution: 3436,
          average_first_response: 5498,
          average_first_resolution_without_business_hours: 1216,
          average_total_resolution_without_business_hours: 3438,
          average_first_response_without_business_hours: 5500,
          total_assigned: 124,
          total_outbound: 235,
          total_closed: 207,
          total_note: 109,
        },
        {
          agent: {
            full_name: 'Mohammad Mahabadi',
            profile_image: null,
            id: 1,
          },
          average_first_resolution: 1213,
          average_total_resolution: 3435,
          average_first_response: 5497,
          average_first_resolution_without_business_hours: 1216,
          average_total_resolution_without_business_hours: 3438,
          average_first_response_without_business_hours: 5500,
          total_assigned: 123,
          total_outbound: 233,
          total_closed: 201,
          total_note: 107,
        },
        {
          agent: {
            full_name: 'Siddhesh Deshpande',
            profile_image: null,
            id: 2,
          },
          average_first_resolution: 1214,
          average_total_resolution: 3436,
          average_first_response: 5498,
          average_first_resolution_without_business_hours: 1216,
          average_total_resolution_without_business_hours: 3438,
          average_first_response_without_business_hours: 5500,
          total_assigned: 124,
          total_outbound: 235,
          total_closed: 207,
          total_note: 109,
        },
        {
          agent: {
            full_name: 'Mohammad Mahabadi',
            profile_image: null,
            id: 1,
          },
          average_first_resolution: 1213,
          average_total_resolution: 3435,
          average_first_response: 5497,
          average_first_resolution_without_business_hours: 1216,
          average_total_resolution_without_business_hours: 3438,
          average_first_response_without_business_hours: 5500,
          total_assigned: 123,
          total_outbound: 233,
          total_closed: 201,
          total_note: 107,
        },
        {
          agent: {
            full_name: 'Siddhesh Deshpande',
            profile_image: null,
            id: 2,
          },
          average_first_resolution: 1214,
          average_total_resolution: 3436,
          average_first_response: 5498,
          average_first_resolution_without_business_hours: 1216,
          average_total_resolution_without_business_hours: 3438,
          average_first_response_without_business_hours: 5500,
          total_assigned: 124,
          total_outbound: 235,
          total_closed: 207,
          total_note: 109,
        },
      ],
      meta: {
        current_page: 2,
        from: 25,
        per_page: 25,
        to: 30,
        total: 30,
      },
    };
    resolve({ data: response });
  });
};

export const callExportAPI = (columns: Columns, sort?: Sort): AxiosPromise => {
  let URL = `${baseURL}export`;
  if (columns) {
    const queryString = columns.columns.map((column: string) => `columns[]=${column}`).join('&');
    URL += `?${queryString}`;
  }
  if (sort && sort.order_by && sort.order_by) {
    URL += `&sort_by=${sort.order_by}&sort_order=${sort.sort_order}`;
  }
  return request(URL, 'GET');
};
