import { PERMISSION } from '@/Configs/Constants';

import type { RouteRecord } from '../types';

const aiRoutes: RouteRecord[] = [
  {
    path: '/ai',
    component: () => {
      return import(/* webpackChunkName: "Reports" */ '@/components/AI/Pages/Index.vue');
    },
    meta: { permissionRequired: PERMISSION.REPORTING__GENERAL__MANAGE },
    name: 'aiBase',
    children: [
      {
        path: '/ai',
        component: require('@/components/AI/Pages/Dashboard.vue').default,
        meta: { permissionRequired: PERMISSION.REPORTING__GENERAL__MANAGE },
        name: 'aiDashboard',
      },
      {
        path: '/ai/terms',
        component: require('@/components/AI/Pages/Dashboard.vue').default,
        meta: { permissionRequired: PERMISSION.REPORTING__GENERAL__MANAGE },
        name: 'aiDashboardTerms',
      },
    ],
  },
];

export default aiRoutes;
