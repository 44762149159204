<template>
  <div class="block h-screen overflow-y-scroll md:h-full">
    <div class="mx-4 flex flex-col items-start px-0 pb-40 pt-20 leading-none md:px-2 md:pb-20 lg:mx-20">
      <t-feature-icon v-if="isFeatureIcon" :icon="icon as FeatureIcon" class="mb-8" />
      <div v-else-if="icon" class="mb-8">
        <img :src="icon" :alt="title" class="h-88px w-88px" />
      </div>

      <div class="settings-landing-content">
        <h1 v-if="title" class="mb-3 max-w-full text-4xl font-bold text-grey-800 xl:max-w-md">
          {{ title }}
        </h1>
        <div
          v-if="badge"
          class="inline-flex rounded-full bg-grey-300 px-4 py-2 text-center text-sm font-semibold text-grey-700"
        >
          <span>
            {{ badge }}
          </span>
        </div>
        <slot name="entitlement-badge" />
        <div v-if="intro" class="mt-4 max-w-full xl:max-w-md">
          <p class="text-base font-medium text-grey-700" v-html="intro"></p>
        </div>
        <t-unordered-list v-if="list" :items="list" class="mt-8 max-w-full xl:max-w-md" size="lg"></t-unordered-list>
        <section class="mt-8 flex w-full flex-col md:w-auto xl:flex-row">
          <slot />
        </section>
        <slot name="hint" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import TFeatureIcon from '@/components/common/TFeatureIcon';
import { FEATURE_ICON } from '@/Configs/Constants';

import type { FeatureIcon } from '@/types';
import type { PropType } from 'vue';
export default defineComponent({
  name: 'SettingsLanding',
  components: {
    TFeatureIcon,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    badge: {
      type: String,
      default: '',
    },
    icon: {
      type: String as PropType<FeatureIcon | string>,
      default: '',
    },
    intro: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    isFeatureIcon() {
      return Object.values(FEATURE_ICON).includes(this.icon);
    },
  },
});
</script>
