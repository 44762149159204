<template>
  <span>
    <svg
      v-if="showActive === null || showActive"
      :width="props.width"
      :height="props.height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="svg-fill-ai"
    >
      <path
        d="M7.39399 19.3944L7.39395 19.3944C5.67236 18.3928 4.01668 17.0767 2.78606 15.6565C1.54275 14.2216 0.803356 12.763 0.75279 11.4692C0.705171 10.2509 1.26339 9.02474 2.99522 7.96188C4.7673 6.87431 7.72394 5.99585 12.3169 5.59468C16.8852 5.19567 19.5958 5.85499 21.1544 6.91642C22.6681 7.94722 23.2147 9.44262 23.2484 11.08C23.2825 12.7439 22.7793 14.4949 22.2426 15.8627C21.9768 16.5398 21.7088 17.1084 21.5079 17.5065C21.4075 17.7052 21.3242 17.8609 21.2668 17.9656C21.2381 18.0179 21.2158 18.0575 21.2011 18.0835L21.187 18.1083C19.1367 21.2995 17.4325 22.4722 15.5361 22.5526C14.5471 22.5946 13.4272 22.3456 12.0785 21.8032C10.7299 21.2608 9.19851 20.4444 7.39399 19.3944Z"
        stroke="#C6CACD"
        stroke-width="1.5"
        class="svg-stroke"
      />
      <circle cx="12.5" cy="1.5" r="1.5" fill="#C6CACD" class="svg-fill" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5 12.5C5 10.567 6.567 9 8.5 9H16.5C18.433 9 20 10.567 20 12.5C20 14.433 18.433 16 16.5 16H8.5C6.567 16 5 14.433 5 12.5ZM10 12.5C10 13.3284 9.32843 14 8.5 14C7.67157 14 7 13.3284 7 12.5C7 11.6716 7.67157 11 8.5 11C9.32843 11 10 11.6716 10 12.5ZM16.5 14C17.3284 14 18 13.3284 18 12.5C18 11.6716 17.3284 11 16.5 11C15.6716 11 15 11.6716 15 12.5C15 13.3284 15.6716 14 16.5 14Z"
        fill="#C6CACD"
        class="svg-fill"
      />
    </svg>
    <span class="icon-active">
      <svg
        :width="props.width"
        :height="props.height"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14 1.5C14 2.32843 13.3284 3 12.5 3C11.6716 3 11 2.32843 11 1.5C11 0.671573 11.6716 0 12.5 0C13.3284 0 14 0.671573 14 1.5ZM12.2517 4.84774C-6.30559 6.46861 -0.108271 15.8977 7.0168 20.0429C14.2633 24.2593 17.6361 25.033 21.8284 18.4976C21.8284 18.4976 21.8294 18.4958 21.8315 18.4922C22.02 18.1646 30.6054 3.24465 12.2517 4.84774ZM8.5 9C6.567 9 5 10.567 5 12.5C5 14.433 6.567 16 8.5 16H16.5C18.433 16 20 14.433 20 12.5C20 10.567 18.433 9 16.5 9H8.5ZM10 12.5C10 13.3284 9.32843 14 8.5 14C7.67157 14 7 13.3284 7 12.5C7 11.6716 7.67157 11 8.5 11C9.32843 11 10 11.6716 10 12.5ZM16.5 14C17.3284 14 18 13.3284 18 12.5C18 11.6716 17.3284 11 16.5 11C15.6716 11 15 11.6716 15 12.5C15 13.3284 15.6716 14 16.5 14Z"
          :fill="fill"
        />
      </svg>
    </span>
  </span>
</template>

<script lang="ts" setup>
export type Props = {
  width?: string;
  height?: string;
  fill?: string;
  showActive?: boolean | null;
};

const props = withDefaults(defineProps<Props>(), {
  width: '24',
  height: '24',
  fill: 'white',
  showActive: null,
});
</script>

<style scoped lang="scss">
.icon-navigation {
  &.active {
    .svg-fill-ai {
      @apply hidden;
    }
  }
  &.inactive {
    .svg-fill-ai {
      @apply block;
    }
  }
}
</style>
