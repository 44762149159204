<template>
  <div class="flex min-w-0 flex-1" style="position: relative">
    <div
      v-show="bulkSelectedTickets === 0"
      class="relative flex min-w-0 flex-1 flex-col"
      :class="{ 'bg-grey-200': isInternalCommentRedesignEnabled }"
    >
      <div class="hidden-lg-up flex">
        <router-link to="/tickets" class="absolute pl-4 pt-4" style="margin-left: -4px">
          <i class="material-icons md-24">arrow_back</i>
        </router-link>
      </div>
      <div
        class="items-center bg-white lg:px-8"
        :class="{
          'border-b-2 border-grey-200 lg:pt-4': isInternalCommentRedesignEnabled,
          'lg:mt-4': !isInternalCommentRedesignEnabled,
        }"
      >
        <div class="">
          <div
            class="mb-2 flex flex-1 items-center py-2 lg:py-0"
            :class="{
              'border-b-2 border-grey-200 lg:border-b-0': !isInternalCommentRedesignEnabled,
            }"
            style="min-height: 46px"
          >
            <div class="_700 hidden-lg-up my-2 flex min-w-0 items-center" style="padding-left: 43px">
              <h6 class="mb-0 text-ellipsis">{{ ticket.contact.full_name }}</h6>
              <visitor-online-status
                v-if="
                  ticket.channel.type === 'CHAT' && ticket.contact.full_name !== null && ticket.contact.phone !== null
                "
                :contact-phone="ticket.contact.phone"
                @online-status-changed="updateContactOnlineStatus"
                @client-typing="onClientComposing"
              />
            </div>
            <div class="ml-auto mr-4 flex select-none items-center lg:mr-0">
              <div class="text-dark hidden-md-down mr-2 flex flex-1 select-none items-center text-grey-600">
                <div
                  v-show="canAddReminder()"
                  v-tooltip="{
                    content: $t('tickets.tooltip_set_reminder'),
                    placement: 'bottom',
                    distance: 5,
                    delay: { show: 1750 },
                  }"
                  role="button"
                  class="selector-conversation-add-reminder"
                  @click="remindTicketModal()"
                >
                  <i class="material-icons cursor-pointer text-grey-600 hover:brightness-90">alarm</i>
                </div>
                <div
                  v-if="ticket.reminder && ticket.reminder.user_id === $root.user.id"
                  v-tooltip="{
                    content: $t('tickets.tooltip_cancel_reminder'),
                    placement: 'bottom',
                    distance: 5,
                    delay: { show: 1750 },
                  }"
                >
                  <i
                    class="material-icons text-info selector-conversation-cancel-reminder cursor-pointer hover:brightness-90"
                    role="button"
                    @click="cancelReminder()"
                  >
                    alarm
                  </i>
                </div>
                <div
                  v-tooltip="{
                    content: $t('tickets.tooltip_starred'),
                    placement: 'bottom',
                    distance: 5,
                    delay: { show: 1750 },
                  }"
                  class="hidden-md-down selector-conversation-favorite ml-2 cursor-pointer items-center"
                  role="button"
                  @click="triggerStarred()"
                >
                  <i class="material-icons flex-1 text-grey-600 hover:brightness-90" :class="{ starred: hasStarred }">
                    star
                  </i>
                </div>
              </div>
              <div>
                <assign
                  v-if="isEntitledToAssignment"
                  :ticket="ticket"
                  @assigned="handleOnAssigned"
                  @handle-assign-to-self="handleAssignToSelf"
                  @handle-assign-to-team="handleAssignToTeam"
                />
              </div>
              <a
                v-if="
                  ticketCanBeClosed() &&
                  ticketRequiresLabel() &&
                  userStore.hasPermission(PERMISSION.INBOX__CONVERSATIONS__CLOSE)
                "
                v-tooltip="{
                  content: $t('tickets.ticket_requires_label'),
                  placement: 'bottom',
                  distance: 5,
                  delay: { show: 1750 },
                }"
                class="btn-ticket-action ml-2 flex cursor-pointer items-center justify-center rounded-full bg-grey-200 p-2 px-3 hover:brightness-90"
                style="height: 46px; width: 46px"
                @click="showAlert($t('tickets.ticket_requires_label'))"
              >
                <i class="material-icons md-18 text-grey-600">check</i>
              </a>
              <a
                v-else-if="ticketCanBeClosed() && userStore.hasPermission(PERMISSION.INBOX__CONVERSATIONS__CLOSE)"
                v-tooltip="{
                  content: $t('tickets.tooltip_close_ticket'),
                  placement: 'bottom',
                  distance: 5,
                  delay: { show: 1750 },
                }"
                class="btn-ticket-action selector-conversation-close ml-2 flex cursor-pointer items-center justify-center rounded-full bg-grey-200 p-2 px-3 hover:brightness-90"
                style="height: 46px; width: 46px"
                @click="closeTicket(ticket.id)"
              >
                <i class="material-icons md-18 text-grey-600">check</i>
              </a>
              <a
                v-if="ticketCanBeReopened() && userStore.hasPermission(PERMISSION.INBOX__CONVERSATIONS__CREATE)"
                v-tooltip="{
                  content: $t('tickets.tooltip_reopen_ticket'),
                  placement: 'bottom',
                  distance: 5,
                  delay: { show: 1750 },
                }"
                class="btn-ticket-action selector-conversation-reopen ml-2 flex cursor-pointer items-center justify-center rounded-full bg-grey-200 p-2 px-3 hover:brightness-90"
                style="height: 46px; width: 46px"
                @click="reOpenTicket()"
              >
                <replay-linear size="1.75rem" class="-rotate-45 transform" data-test="headerReopenConversationButton" />
              </a>
            </div>
          </div>
          <div class="flex" :class="{ 'border-b-2 border-grey-200': !isInternalCommentRedesignEnabled }">
            <div class="ticket-labels min-w-0 flex-1 items-center">
              <div class="flex items-start">
                <div class="ticket-labels-title _700 hidden-md-down mt-3 min-w-0 text-base lg:mt-2">
                  <div v-if="ticket.channel.type === 'EMAIL'" class="truncate">
                    <channel-icon :channel="ticket.channel.type" class="mr-2"></channel-icon>
                    <span data-hj-suppress>{{ ticket.subject }}</span>
                  </div>
                  <div v-else class="flex items-center text-base">
                    <span class="text-ellipsis">
                      <channel-icon :channel="ticket.channel.type" class="mr-2"></channel-icon>
                      <span data-hj-suppress>{{ ticket.contact.full_name }}</span>
                    </span>

                    <visitor-online-status
                      v-if="
                        ticket.channel.type === 'CHAT' &&
                        ticket.contact.full_name !== null &&
                        ticket.contact.phone !== null
                      "
                      :contact-phone="ticket.contact.phone"
                      @online-status-changed="updateContactOnlineStatus"
                      @client-typing="onClientComposing"
                    />
                  </div>
                </div>

                <div class="ticket-subject _700 hidden-lg-up min-w-0 pl-4 text-grey-600">
                  <div v-if="ticket.channel.type === 'EMAIL'" class="flex flex-nowrap items-center text-ellipsis">
                    <channel-icon :channel="ticket.channel.type" class="mr-2"></channel-icon>
                    {{ ticket.subject }}
                  </div>
                  <div v-else class="flex flex-nowrap items-center">
                    <channel-icon :channel="ticket.channel.type" class="mr-2"></channel-icon>
                    {{ getChannelName(ticket.channel.type) }}
                  </div>
                </div>
                <div class="ml-auto flex items-start pt-1 leading-none lg:pt-0">
                  <div v-show="$root.labels.length > 0" class="ml-2 flex flex-wrap items-center">
                    <label-selector
                      v-if="isEntitledToLabels"
                      :wrap="false"
                      :selection="ticket.labels"
                      :ticket="ticket"
                      :ticket-id="ticket.id"
                      @add-label="addLabel"
                      @delete-label="deleteLabel"
                    />
                  </div>
                  <dropdown
                    :static="true"
                    :search="false"
                    class="lg:mt-2"
                    style="width: auto"
                    :scroll-on-hover="false"
                    :auto-height="true"
                  >
                    <template #toggle>
                      <a
                        v-tooltip="{
                          content: $t('tickets.tooltip_more_options'),
                          placement: 'bottom',
                          distance: 5,
                          delay: { show: 1750 },
                        }"
                        class="mx-4 cursor-pointer select-none select-none text-grey-600 lg:mx-0 lg:ml-2"
                      >
                        <i class="material-icons text-grey-600" style="line-height: 10px; margin-top: 8px">
                          more_horiz
                        </i>
                      </a>
                    </template>
                    <template #heading>
                      <div
                        class="_500 hidden-lg-up select-none border-grey-200 py-4 text-center text-base leading-none text-black"
                      >
                        {{ $t('general.nav_options') }}
                      </div>
                    </template>
                    <template #body>
                      <div
                        class="align-items hidden-lg-up selector-conversation-toggle-sidebar m-0 flex cursor-pointer p-3 px-3 leading-none text-black hover:bg-grey-200"
                        role="button"
                        @click="toggleSidebar"
                      >
                        <div class="container-icon md-18">
                          <i class="material-icons md-18 ticket-option-icon">vertical_split</i>
                        </div>
                        <div class="ml-2 flex items-center">
                          {{ $t('tickets.conversation_view_sidebar') }}
                        </div>
                      </div>
                      <div
                        v-if="
                          ticket.status === 'CLOSED' &&
                          ticketCanBeReopened() &&
                          userStore.hasPermission(PERMISSION.INBOX__CONVERSATIONS__CREATE)
                        "
                        class="align-items selector-conversation-reopen m-0 flex cursor-pointer p-3 px-3 leading-none text-black hover:bg-grey-200"
                        role="button"
                        @click="reOpenTicket()"
                      >
                        <div class="container-icon md-18">
                          <i class="material-icons md-18 ticket-option-icon">settings_backup_restore</i>
                        </div>
                        <div class="ml-2 flex items-center">
                          {{ $t('tickets.bulk_action_reopen') }}
                        </div>
                      </div>
                      <div
                        v-if="isEntitledToForwarding"
                        class="align-items selector-conversation-forward m-0 flex cursor-pointer p-3 px-3 leading-none text-black hover:bg-grey-200"
                        role="button"
                        @click="forwardTicketModal()"
                      >
                        <div class="container-icon md-18">
                          <i class="material-icons md-18 ticket-option-icon flex items-center">forward</i>
                        </div>
                        <div class="ml-2 flex items-center">
                          {{ $t('tickets.conversation_forward') }}
                        </div>
                      </div>
                      <div
                        class="align-items selector-conversation-link m-0 flex cursor-pointer p-3 px-3 leading-none text-black hover:bg-grey-200"
                        role="button"
                        @click="openAddRelatedTicketModal()"
                      >
                        <div class="container-icon md-18">
                          <i class="material-icons md-18 ticket-option-icon">link</i>
                        </div>
                        <div class="ml-2 flex flex-shrink-0 items-center">
                          {{ $t('tickets.conversation_link') }}
                        </div>
                      </div>
                      <div
                        v-show="canAddReminder()"
                        class="align-items selector-conversation-add-reminder m-0 flex cursor-pointer p-3 px-3 leading-none text-black hover:bg-grey-200"
                        role="button"
                        @click="remindTicketModal()"
                      >
                        <div class="container-icon md-18">
                          <i class="material-icons md-18 ticket-option-icon">alarm</i>
                        </div>
                        <div class="ml-2 flex items-center">
                          {{ $t('tickets.conversation_reminder') }}
                        </div>
                      </div>
                      <div
                        class="align-items hidden-lg-up selector-conversation-favorite m-0 flex cursor-pointer p-3 px-3 leading-none text-black hover:bg-grey-200"
                        role="button"
                        @click="triggerStarred()"
                      >
                        <template v-if="hasStarred">
                          <div class="container-icon md-18">
                            <i class="material-icons md-18 ticket-option-icon">star</i>
                          </div>
                          <div class="ml-2 flex items-center">
                            {{ $t('tickets.conversation_un_mark_as_star') }}
                          </div>
                        </template>
                        <template v-else>
                          <div class="container-icon md-18">
                            <i class="material-icons md-18 ticket-option-icon">star</i>
                          </div>
                          <div class="ml-2 flex items-center">
                            {{ $t('tickets.conversation_mark_as_star') }}
                          </div>
                        </template>
                      </div>
                      <div
                        class="align-items selector-conversation-merge m-0 flex cursor-pointer p-3 px-3 leading-none text-black hover:bg-grey-200"
                        role="button"
                        @click="openMergeTicketModal()"
                      >
                        <div class="container-icon md-18">
                          <i class="material-icons md-18 ticket-option-icon">merge_type</i>
                        </div>
                        <div class="ml-2 flex items-center">
                          {{ $t('tickets.conversation_merge') }}
                        </div>
                      </div>
                      <div
                        v-if="showSendAsOption && userStore.hasPermission(PERMISSION.INBOX__CONVERSATIONS__CREATE)"
                        class="align-items selector-conversation-send-as m-0 flex cursor-pointer p-3 px-3 leading-none text-black hover:bg-grey-200"
                        role="button"
                        @click="openSendAsModal()"
                      >
                        <div class="container-icon md-18">
                          <i class="material-icons md-18 ticket-option-icon">email</i>
                        </div>
                        <div class="ml-2 flex items-center">
                          {{ $t('tickets.conversation_change_sender') }}
                        </div>
                      </div>
                      <div
                        v-if="userStore.hasPermission(PERMISSION.INBOX__CONVERSATIONS__DOWNLOAD)"
                        class="align-items app-hidden selector-conversation-export-pdf m-0 flex cursor-pointer p-3 px-3 leading-none text-black hover:bg-grey-200"
                        role="button"
                        @click="exportAsPdf()"
                      >
                        <div class="container-icon md-18">
                          <i class="material-icons md-18 ticket-option-icon">picture_as_pdf</i>
                        </div>
                        <div class="ml-2 flex items-center">
                          {{ $t('tickets.conversation_download') }}
                        </div>
                      </div>
                      <div
                        v-if="!$root.isDesktopApp"
                        class="align-items selector-conversation-print m-0 flex cursor-pointer p-3 px-3 leading-none text-black hover:bg-grey-200"
                        role="button"
                        @click="printTicket()"
                      >
                        <div class="container-icon md-18">
                          <i class="material-icons md-18 ticket-option-icon">print</i>
                        </div>
                        <div class="ml-2 flex items-center">
                          {{ $t('tickets.conversation_print') }}
                        </div>
                      </div>
                      <div
                        v-if="ticket.status != 'INVALID'"
                        class="align-items selector-conversation-mark-spam m-0 flex cursor-pointer p-3 px-3 leading-none text-black hover:bg-grey-200"
                        role="button"
                        @click="markAsSpam()"
                      >
                        <div class="container-icon md-18">
                          <i class="material-icons md-18 ticket-option-icon">error_outline</i>
                        </div>
                        <div class="ml-2 flex items-center">
                          {{ $t('tickets.conversation_mark_as_spam') }}
                        </div>
                      </div>
                      <div
                        v-if="ticket.status == 'INVALID'"
                        class="align-items selector-conversation-unmark-spam m-0 flex cursor-pointer p-3 px-3 leading-none text-black hover:bg-grey-200"
                        role="button"
                        @click="unMarkAsSpam()"
                      >
                        <div class="container-icon md-18">
                          <i class="material-icons md-18 ticket-option-icon">error_outline</i>
                        </div>
                        <div class="ml-2 flex items-center">
                          {{ $t('tickets.conversation_un_mark_as_spam') }}
                        </div>
                      </div>
                      <div
                        v-if="$root.isDesktopApp || $root.mobileDevice"
                        class="align-items selector-conversation-unmark-spam m-0 flex cursor-pointer p-3 px-3 leading-none text-black hover:bg-grey-200"
                        role="button"
                        @click="copyUrl()"
                      >
                        <div class="container-icon md-18">
                          <i class="material-icons md-18 ticket-option-icon">content_copy</i>
                        </div>
                        <div class="ml-2 flex items-center">
                          {{ $t('tickets.copy_url') }}
                        </div>
                      </div>
                      <div
                        v-if="userStore.hasPermission(PERMISSION.INBOX__CONVERSATIONS__DELETE)"
                        class="align-items selector-conversation-delete m-0 flex cursor-pointer p-3 px-3 leading-none text-black hover:bg-grey-200"
                        role="button"
                        @click="deleteTicket()"
                      >
                        <div class="container-icon md-18">
                          <i class="material-icons md-18 ticket-option-icon">delete_forever</i>
                        </div>
                        <div class="ml-2 flex items-center">
                          {{ $t('tickets.conversation_delete') }}
                        </div>
                      </div>
                    </template>
                  </dropdown>
                  <div class="hidden-md-down ml-3 select-none lg:mt-2">
                    <i
                      class="material-icons selector-conversation-toggle-sidebar cursor-pointer text-grey-600"
                      role="button"
                      style="margin-top: 1px"
                      @click="toggleSidebar"
                    >
                      vertical_split
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-show="showInitOverlay || loadingMore"
        style="position: absolute; z-index: 99999999; top: 92px; right: 0; left: 0; bottom: 0"
        :class="{ 'bg-white': showInitOverlay }"
        class="my-4 flex items-center justify-center"
      >
        <div
          class="shadow"
          style="
            width: 45px;
            height: 45px;
            background: white;
            border-radius: 100%;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            style="margin: auto; background: rgb(255, 255, 255); display: block; shape-rendering: auto"
            width="34px"
            height="34px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
          >
            <circle
              cx="50"
              cy="50"
              fill="none"
              stroke="#14B29F"
              stroke-width="10"
              r="35"
              stroke-dasharray="164.93361431346415 56.97787143782138"
              transform="rotate(284.393 50 50)"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                repeatCount="indefinite"
                dur="1s"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
              ></animateTransform>
            </circle>
          </svg>
        </div>
      </div>

      <div
        id="MessagesContainer"
        ref="messageContainer"
        class="scroll-on-hover relative min-w-0 flex-1 pt-0 lg:mr-4 lg:pl-8 lg:pt-4"
        data-list="ticket"
        infinite-wrapper
        :class="{
          'bg-grey-100 lg:bg-white': ticket.channel.type === 'EMAIL' && !isInternalCommentRedesignEnabled,
        }"
      >
        <infinite-loading direction="top" force-use-infinite-wrapper="true" @infinite="loadMoreMessages">
          <template #spinner>
            <div></div>
          </template>
          <template #no-more>
            <div></div>
          </template>
          <template #no-results>
            <div></div>
          </template>
          <template #error><span @click="loadMoreMessages">- Try again -</span></template>
        </infinite-loading>

        <div id="feed-container" style="flex-grow: 1" class="min-w-0">
          <div v-if="ticket.archive" class="mb-4">
            <div
              class="relative m-2 inline select-none overflow-hidden rounded-lg bg-orange-light px-4 py-4 pl-12 text-left leading-normal lg:m-0"
              @click="upgrade"
            >
              <div class="avatar-container-left overflow-hidden" style="top: 0; left: 0">
                <span
                  class="circle inline-block-40px avatar avatar-left flex-shrink-0 select-none items-center justify-center bg-orange leading-none text-white"
                  style="display: inline-flex; font-size: 14px; width: 44px; height: 44px"
                >
                  <i class="material-icons">error_outline</i>
                </span>
              </div>
              <div class="_500 flex-col text-grey-600">
                {{ $t('tickets.archived_messages') }}
                <span
                  class="hidden-md-down ml-4 cursor-pointer rounded bg-orange px-4 py-2 text-white hover:brightness-90"
                >
                  Upgrade
                </span>
              </div>
            </div>
          </div>
          <div
            v-for="(message, i) in timelineArray"
            :key="i"
            class="event-container min-w-0"
            :class="{ 'pr-2 lg:pr-0': ticket.channel.type !== 'EMAIL' }"
          >
            <div v-if="message.type === 'AUDIT'" class="min-w-0">
              <audit-message v-show="messagesLoaded" :message="message" :iteration="i"></audit-message>
            </div>
            <div v-else>
              <div v-if="ticket.channel.type === 'EMAIL'">
                <email-message
                  :message="message"
                  :messages="messages"
                  :is-latest="isLatest(message)"
                  :ticket="ticket"
                  :page="page"
                  @delete-ticket-message="deleteMessage"
                  @mark-previous-tasks="markPreviousTasks"
                  @move-message-to-new-ticket="messageToNewTicket"
                  @delete-message="deleteMessage"
                  @translate-inbox-message="
                    (isReverting: boolean) =>
                      translateInboundMessage(
                        {
                          message_id: message.id,
                          ticket_id: message.ticket_id,
                          prompt: message.email_message?.html?.length ? message.email_message?.html : message.message,
                          isEmail: true,
                        },
                        isReverting,
                      )
                  "
                />
              </div>
              <div v-else>
                <!-- style="height:300px"-->
                <chat-message
                  :message="message"
                  :is-latest-inbound="isLatestInbound(message)"
                  :iteration="i"
                  :ticket="ticket"
                  :public-provider="computedPublicProvider"
                  :reply-to="replyTo"
                  @loaded="chatMessageLoaded"
                  @delete-ticket-message="deleteMessage"
                  @mark-previous-tasks="markPreviousTasks"
                  @delete-message="deleteMessage"
                  @translate-inbox-message="
                    (isReverting: boolean) =>
                      translateInboundMessage(
                        {
                          message_id: message.id,
                          ticket_id: message.ticket_id,
                          prompt: message.message,
                          isEmail: false,
                        },
                        isReverting,
                      )
                  "
                ></chat-message>
              </div>
            </div>
            <template v-if="showNewMessageLineIndicator(message.id, i) && newMessagesCount">
              <div
                id="newMessagesIndicatorLine"
                ref="lastSeenMessageId"
                class="w-100 my-3 select-none text-center text-red"
                style="
                  height: 2px;
                  margin-bottom: 25px;
                  background: #eaedf5;
                  background: radial-gradient(circle, #eaedf5 0%, rgba(255, 255, 255, 1) 100%);
                "
              >
                <div
                  class="b-a-lightblue relative inline-block bg-white text-center text-grey-600"
                  style="top: -17px; border-radius: 13px; padding: 6px 14px"
                >
                  {{ $t('tickets.new_messages') }}
                </div>
              </div>
            </template>
          </div>
          <chat-message
            v-if="
              ticket.status !== 'CLOSED' && ticket.status !== 'INVALID' && contactIsOnline && clientComposing !== null
            "
            :message="clientComposing"
            :ticket="{ channel: {} }"
          ></chat-message>
          <div v-if="ticket.reminder && ticket.reminder.user_id === $root.user.id" class="m-b block text-center">
            <div class="alert alert-info mt-4 inline">
              <i class="fa fa-clock-o"></i>
              <span
                v-html="
                  $t('tickets.reminder_set', {
                    at: formatTime(ticket.reminder.remind_at),
                  })
                "
              ></span>
              (
              <a @click="cancelReminder()">{{ $t('tickets.reminder_cancel') }}</a>
              )
            </div>
          </div>
          <div v-show="showReplyForm()" class="flex pb-0 lg:pb-4">
            <div class="min-w-0 flex-1" style="word-wrap: break-word">
              <div class="pos-relative flex-wrap">
                <div
                  v-if="!isInternalCommentRedesignEnabled && showReplyAssign"
                  class="assign dark-light b-a-lightblue rounded-lg px-8 text-center align-middle"
                >
                  <div class="flex justify-center text-center text-grey-600">
                    <assign
                      v-if="isEntitledToAssignment && showReplyAssign"
                      :ticket="ticket"
                      @assigned="handleOnAssigned"
                    ></assign>
                  </div>
                </div>
                <t-inline-banner
                  v-if="shouldShowRuleBanner"
                  type="default"
                  class="twenty-four-hr-banner"
                  :class="{
                    'twenty-four-hr-banner__grey': isInternalCommentRedesignEnabled,
                  }"
                >
                  <template #icon>
                    <clock-linear />
                  </template>
                  <p class="twenty-four-hr-banner__message">{{ ruleBannerMessage }}</p>
                  <template #action>
                    <t-button
                      class="twenty-four-hr-banner__btn"
                      href="https://help.trengo.com/en/articles/69467-limitations-of-the-whatsapp-business-api"
                      btn-style="secondary"
                      size="sm"
                      target="_blank"
                    >
                      {{ $t('whatsapp.learn_more') }}
                      <external-link-linear class="action-link" />
                    </t-button>
                  </template>
                </t-inline-banner>
                <composer
                  v-if="!isInternalCommentRedesignEnabled && ticketLoaded"
                  :ticket="ticket"
                  :path="'/tickets/' + ticket.id"
                  :draft-key="'ticket' + ticket_id"
                  :public-provider="computedPublicProvider"
                  :reply-to="replyTo"
                  :messages="messages"
                  :contact-is-online="contactIsOnline"
                  :has-access-to-channel="hasAccessToChannel"
                  @append-message="appendMessage"
                  @focus-editor="focusOnEditor"
                  @draft-loaded="draftLoaded = true"
                  @cc-update="ccUpdate"
                  @subject-update="subjectUpdate"
                  @handle-consolidated-message-endpoint-closed-ticket="handleConsolidatedMessageEndpointClosedTicket"
                  @update-send-and-close-ticket-status="updateSendAndCloseTicketStatus"
                />

                <comment-composer
                  v-else-if="ticketLoaded"
                  :is-ticket-assigned="ticket.status === 'ASSIGNED'"
                  :show-reply="isReplyFormVisible"
                >
                  <template #public-reply>
                    <div
                      class="pos-relative h-full flex-wrap rounded-b-xl bg-white pt-1"
                      data-test="commentComposerPublicReplyTabWrapper"
                    >
                      <div v-if="isAssignOverlayVisible" style="height: 148px"></div>
                      <div
                        v-if="isInternalCommentRedesignEnabled && isAssignOverlayVisible"
                        class="assign dark-light rounded-lg px-8 text-center align-middle"
                      >
                        <div class="flex justify-center text-center text-grey-600">
                          <assign
                            v-if="isEntitledToAssignment"
                            :ticket="ticket"
                            data-test="commentComposerAssignButton"
                            @assigned="handleOnAssigned"
                          />

                          <div v-if="ticket.status === 'CLOSED'">
                            <t-button
                              btn-style="secondary"
                              :disabled="isReopening"
                              data-test="messageFeedReopenConversationButton"
                              @click="reOpenTicket"
                            >
                              <div class="flex items-center justify-center">
                                <replay-linear class="mr-2 -rotate-45 transform self-center" size="1.25rem" />
                                <span class="text-sm">{{ $t('tickets.reopen_conversation') }}</span>
                              </div>
                            </t-button>
                          </div>
                        </div>
                      </div>

                      <composer
                        v-if="isInternalCommentRedesignEnabled && ticketLoaded && !isAssignOverlayVisible"
                        :ticket="ticket"
                        :path="'/tickets/' + ticket.id"
                        :draft-key="'ticket' + ticket_id"
                        :public-provider="computedPublicProvider"
                        :reply-to="replyTo"
                        :messages="messages"
                        :contact-is-online="contactIsOnline"
                        :has-access-to-channel="hasAccessToChannel"
                        @append-message="appendMessage"
                        @focus-editor="focusOnEditor"
                        @draft-loaded="draftLoaded = true"
                        @cc-update="ccUpdate"
                        @subject-update="subjectUpdate"
                        @handle-consolidated-message-endpoint-closed-ticket="
                          handleConsolidatedMessageEndpointClosedTicket
                        "
                        @update-send-and-close-ticket-status="updateSendAndCloseTicketStatus"
                      />
                    </div>
                  </template>

                  <template #note>
                    <internal-comment-composer
                      :ticket="ticket"
                      :message-count="messages.length"
                      :public-provider="computedPublicProvider"
                    />
                  </template>
                </comment-composer>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="showNewMessagesScrollLink"
        style="position: absolute; bottom: 70px; right: 50px"
        class="hidden-lg-down"
      >
        <div class="relative" style="z-index: 999">
          <div>
            <span
              v-if="newMessagesCount"
              class="success label rounded"
              style="position: relative; left: 60px; bottom: 20px"
            >
              {{ newMessagesCount }}
            </span>
            <div
              class="inline-block-56px pointer flex h-56 items-center rounded bg-white leading-none shadow"
              @click="newMessagesScrollLinkClicked"
            >
              <i style="font-size: 20px; line-height: 55px" class="material-icons">keyboard_arrow_down</i>
            </div>
          </div>
        </div>
      </div>
      <ticket-note-composer
        v-if="!isInternalCommentRedesignEnabled && ticketLoaded"
        :public-provider="computedPublicProvider"
        :ticket="ticket"
        :ticket-id="ticket_id"
        @call-parent-append-message-method="appendMessage"
        @call-parent-new-message-method="newMessage"
      ></ticket-note-composer>
    </div>
    <ticket-sidebar
      v-if="ticketLoaded && bulkSelectedTickets === 0 && showSidebar"
      class="sidebar-container w-350 flex border-l border-grey-300"
      :ticket="ticket"
      :public-provider="computedPublicProvider"
      :visitor-online-status="contactIsOnline"
      :show-sidebar="showSidebar"
      @close-sidebar="toggleSidebar"
      @update-contact-name="updateContactName"
    />
    <required-custom-field-modal v-model="requiredModalOpen" @close="closeTicket(ticket.id, $event)" />
  </div>
</template>

<script lang="ts">
import { ClockLinear, ExternalLinkLinear, ReplayLinear } from '@trengo/trengo-icons';
import { sanitize } from 'dompurify';
import _, { includes, last, map, sortBy, toArray } from 'lodash';
import moment from 'moment';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import { ENDPOINT } from '@/api/constants';
import CommentComposer from '@/components/ReplyForm/CommentComposer.vue';
import InternalCommentComposer from '@/components/ReplyForm/InternalCommentComposer.vue';
import TicketSidebar from '@/components/TicketSidebar/Index.vue';
import RequiredCustomFieldModal from '@/components/TicketSidebar/RequiredCustomFieldModal';
import VisitorOnlineStatus from '@/components/VisitorOnlineStatus.vue';
import { CHANNEL_TYPE, PERMISSION, FEATURE_FLAG_INBOX, MESSAGE_TYPE, FEATURE } from '@/Configs/Constants';
import { STATUS_CODE } from '@/Configs/Constants/StatusCodes';
import eventBus from '@/eventBus';
import { useFeatureFlagStore, useEntitlementsStore, useUserStore } from '@/store/pinia';
import { useInboundTranslationStore } from '@/store/pinia/translation/useInboundTranslationStore';
import InfiniteLoading from '@/UIKit/Molecules/InfiniteLoading';
import { formatTime } from '@/util/date';
import { retryRequestIfResponseMatchesStatusCode } from '@/util/request';

import AuditMessage from './AuditMessage.vue';
import channelIcon from './ChannelIcon';
import LabelSelector from './LabelSelector';
import Composer from './ReplyForm/Composer.vue';
import Dropdown from './ReplyForm/Dropdown';
import assign from '../components/Assign.vue';
import chatMessage from '../components/ChatMessage';
import emailMessage from '../components/EmailMessage.vue';
import ticketNoteComposer from '../components/ReplyForm/TicketNoteComposer.vue';
import PusherHelper from '../mixins/PusherHelper';
import voipCallable from '../mixins/VoipCallable';
import ticketRepository from '../repositories/Ticket';

import type { UserLocales } from './ReplyForm/TranslationOption/useLocales';
import type { consolidatedSendResponseTicket } from '@/api/modules/messages';
import type { FeedMessage } from '@/types/message';

const STATUS_CLOSED = 'CLOSED';
const STATUS_INVALID = 'INVALID';

export default defineComponent({
  name: 'Ticket',

  components: {
    assign,
    AuditMessage,
    channelIcon,
    chatMessage,
    ClockLinear,
    CommentComposer,
    Composer,
    Dropdown,
    emailMessage,
    ExternalLinkLinear,
    InfiniteLoading,
    InternalCommentComposer,
    LabelSelector,
    ReplayLinear,
    RequiredCustomFieldModal,
    ticketNoteComposer,
    TicketSidebar,
    VisitorOnlineStatus,
  },

  mixins: [voipCallable, PusherHelper],
  props: {
    tickets: {
      type: Array,
      default: () => [],
    },
  },
  data: function () {
    return {
      ticket: {
        agent: {},
        contact: {},
        channel: {},
        closed_by: {},
        watchers: {},
        starred: {},
        attachments: [],
        messaging_attachments: [],
        channelMeta: null,
        labels: [],
        user_id: null,
        team_id: null,
        status: null,
      },
      ticketsData: [...this.tickets],
      ticket_id: null,
      messages: [],
      canBeReplied: true,
      hasMoreMessages: false,
      page: 0,
      isReopening: false,
      isWorking: false,
      loading: false,
      contactIsOnline: false,
      messagesLoaded: false,
      audits: [],
      scrollTop: false,
      clientComposing: null,
      replyTo: {},
      showNewMessagesScrollLink: false,
      lastFocusedMessageId: null,
      lastSeenMessageId: null,
      bulkSelectedTickets: 0,
      showSidebar: false,
      allLoaded: false,
      showInitOverlay: false,
      draftLoaded: false,
      loadingMore: false,
      loadingMoreTimer: null,
      pusherTicketChannel: null,
      legacyPusherTicketChannel: null,
      infiniteId: 0,
      keyId: 0,
      switching: false,
      requiredModalOpen: false,
      activeTab: 0,
      PERMISSION,
      offlineTimer: null,
      closedTicket: null,
      isTicketSentAndClosed: false,
    };
  },

  watch: {
    '$root.hasFocus'(val) {
      if (!val && this.$refs.messageContainer) {
        if (!this.$refs.messageContainer) {
          return;
        }
        if (
          this.$refs.messageContainer.scrollHeight -
            (this.$refs.messageContainer.offsetHeight + this.$refs.messageContainer.scrollTop) <
          200
        ) {
          this.lastSeenMessageId = _.get(_.last(this.timeline), 'id');
        }
      }
    },
    messagesLoaded() {
      if (this.ticketLoaded && !this.allLoaded) {
        this.allLoaded = true;
        this.onAllLoaded();
      }
    },
    ticketLoaded() {
      if (this.messagesLoaded && !this.allLoaded) {
        this.allLoaded = true;
        this.onAllLoaded();
      }
    },
    'ticket.status'(newStatus) {
      if (newStatus !== STATUS_CLOSED) {
        this.closedTicket = null;
      }
    },
    isReopening(newVal) {
      if (newVal === true) {
        this.isTicketSentAndClosed = false;
      }
    },
  },

  computed: {
    ...mapStores(useEntitlementsStore, useUserStore, useInboundTranslationStore),
    ...mapGetters({
      hasEmptyRequiredFields: 'customFields/hasEmptyRequiredFields',
    }),

    isConsolidatedMessageEndpointEnabled() {
      return useFeatureFlagStore().isEnabled(FEATURE_FLAG_INBOX.CONSOLIDATED_MESSAGE_ENDPOINT_ENABLED);
    },

    isEntitledToLabels() {
      return this.entitlementsStore?.isEntitledTo(FEATURE.CUSTOMER_SERVICE__INBOX__MANAGE_LABELS);
    },

    isEntitledToForwarding() {
      return (
        this.entitlementsStore?.isEntitledTo(FEATURE.CUSTOMER_SERVICE__INBOX__FORWARD_CONVERSATIONS) &&
        this.userStore.hasPermission(PERMISSION.INBOX__CONVERSATIONS__CREATE)
      );
    },

    isEntitledToAssignment() {
      return this.entitlementsStore?.isEntitledTo(FEATURE.CUSTOMER_SERVICE__INBOX__ASSIGN_CONVERSATIONS);
    },

    hasAccessToChannel() {
      return this.$root.channels.filter((c) => c.id === this.ticket.channel.id).length > 0;
    },

    ticketLoaded() {
      return this.ticket.id === parseInt(this.$route.params.ticketId) && !this.switching;
    },

    ticketChannel() {
      if (!this.ticket || !this.ticket.channel) {
        return;
      }

      return this.ticket.channel.text;
    },

    timeline() {
      const messages = this.messages;
      const audits = this.audits;
      const hasMoreMessages = this.hasMoreMessages;
      let first_timestamp = moment('1979-12-01').valueOf();
      if (messages.length !== 0) {
        first_timestamp = moment(messages[0].created_at).valueOf();
      }
      const filtered_audits = audits.filter((item) => {
        if (hasMoreMessages === false) {
          return true;
        }
        const item_timestamp = moment(item.created_at).valueOf();
        return item_timestamp >= first_timestamp;
      });
      return sortBy([...filtered_audits, ...toArray(messages)], function (o) {
        return moment
          .tz(o.created_at, window.APP_TIMEZONE)
          .clone()
          .tz(window.APP_TIMEZONE)
          .format('YYYY-MM-DD HH:mm:ss');
      });
    },

    timelineArray() {
      return this.ticketLoaded ? this.timeline : [];
    },

    hasStarred() {
      if (!this.ticket || !this.ticket.starred || !this.ticket.starred.length || this.loading) {
        return;
      }
      return includes(map(this.ticket.starred, 'id'), this.$root.user.id);
    },

    showSendAsOption() {
      return (
        this.hasAccessToChannel &&
        !this.ticket.channel.is_private &&
        this.ticket.channel.type === 'EMAIL' &&
        this.$root.channels.filter((c) => {
          return c.type === 'EMAIL';
        }).length >= 2
      );
    },

    computedPublicProvider() {
      if (this.ticket.channelMeta) {
        switch (this.ticket.channelMeta.type) {
          case 'FACEBOOK_FEED_MESSAGES':
          case 'FACEBOOK_PAGE_MENTIONS':
            return 'FACEBOOK';
          case 'INSTAGRAM_COMMENTS':
            return 'INSTAGRAM';
        }
      }
      return '';
    },

    newMessagesCount() {
      if (this.lastSeenMessageId === null) {
        return 0;
      }
      const messages = this.timeline.filter((t) => t.type !== 'AUDIT');
      if (messages.findIndex((o) => o.id === this.lastSeenMessageId) < 0) {
        return 0;
      }
      return messages.length - 1 - messages.findIndex((o) => o.id === this.lastSeenMessageId);
    },
    waMessageOutsideWindow() {
      if (!this.ticket.channel || this.ticket.channel.type !== 'WA_BUSINESS') {
        return false;
      }

      const now = moment().tz(window.APP_TIMEZONE);

      const isOutsideWindow =
        this.hasCustomerInitiatedMessage &&
        this.messages.filter((m) => {
          const createdAt = moment.tz(m.created_at, window.APP_TIMEZONE);
          const diffInHours = now.diff(createdAt, 'hours');
          return m.type === MESSAGE_TYPE.INBOUND && diffInHours < 24;
        }).length === 0;
      return isOutsideWindow;
    },
    hasCustomerInitiatedMessage() {
      return this.messages.some((m) => m.type === MESSAGE_TYPE.INBOUND);
    },
    shouldShowRuleBanner() {
      const isWhatsApp = this.ticket.channel.type === CHANNEL_TYPE.WA_BUSINESS;
      if (!isWhatsApp) {
        return false;
      }
      return this.waMessageOutsideWindow || !this.hasCustomerInitiatedMessage;
    },
    ruleBannerMessage() {
      return !this.hasCustomerInitiatedMessage
        ? this.$t('tickets.customer_should_initiate_conversation')
        : this.$t('tickets.twenty_four_hour_response');
    },
    ruleBannerType() {
      return !this.hasCustomerInitiatedMessage ? 'default' : 'warning';
    },
    isInternalCommentRedesignEnabled() {
      return useFeatureFlagStore().isEnabled(FEATURE_FLAG_INBOX.INTERNAL_COMMENT_TABS);
    },
    isReplyFormVisible() {
      if (this.isInternalCommentRedesignEnabled && this.ticket.channel.type === 'VOIP') {
        return false;
      }

      return this.$root.user.role.name !== 'observer';
    },

    isAssignOverlayVisible() {
      return this.ticket.status === 'OPEN' || this.ticket.status === 'CLOSED';
    },

    showReplyAssign() {
      return this.ticket.status === 'OPEN';
    },
  },

  mounted() {
    this.initTicket();
    this.initSidebarState();

    if (this.ticket?.id) {
      this.inboundTranslationStore.setChannelSupportStatus(this.ticket.id);
    }
  },

  unmounted() {
    this.unbindEvents();
  },

  activated() {
    if (this.$route.params.ticketId == this.ticket.id) {
      //this.scrollToBottom()
    }
  },

  methods: {
    updateContactOnlineStatus(status) {
      this.contactIsOnline = status;
    },

    initTicket() {
      this.resetState();
      this.loadTicket(this.$route.params.ticketId);
      eventBus.$on('ticket.contact.' + this.ticket.contact_id + '.reload', () => {
        eventBus.$emit('ticket.current.reload');
      });
      eventBus.$on('ticket.bulk_selected', (count) => {
        this.bulkSelectedTickets = count;
      });
      this.$root.last_ticket_id = this.$route.params.ticketId;
      this.keyId++;
    },

    async initSidebarState() {
      if (is_mobile_device()) {
        this.showSidebar = false;
      } else {
        this.showSidebar = (await this.$tStorage.getItem('sidebar_state')) == 1;
      }
    },

    addLabel(label) {
      axios.post('/api/v2/tickets/' + this.ticket.id + '/labels', { label_id: label.id });
    },

    deleteLabel(label) {
      axios.delete('/api/v2/tickets/' + this.ticket.id + '/labels/' + label.id);
    },

    chatMessageLoaded() {
      // if scrolled up by ~200px, do not scroll down. Show a clickable message instead
      if (
        this.$refs.messageContainer.scrollHeight -
          (this.$refs.messageContainer.offsetHeight + this.$refs.messageContainer.scrollTop) >=
        200
      ) {
        this.showNewMessagesScrollLink = true;
      } else {
        this.scrollToBottom();
      }
    },

    newMessagesScrollLinkClicked() {
      this.$nextTick(() => {
        let top = this.$refs.messageContainer.scrollHeight;
        const element = document.querySelector('#newMessagesIndicatorLine');
        // first scroll to new messages line if present and current offset still above
        if (
          element &&
          element.parentElement &&
          this.$refs.messageContainer.scrollTop < element.parentElement.offsetTop
        ) {
          top = element.parentElement.offsetTop;
        }
        this.$refs.messageContainer.scroll({
          top: top,
          behavior: 'smooth',
        });
      });
    },

    showNewMessageLineIndicator(message_id, timeline_index) {
      // Only show new message indicator if lastSeenMessage id equals current message id in timeline and if messages not of current user id
      return (
        this.lastSeenMessageId === message_id &&
        timeline_index !== this.timeline.length - 1 &&
        this.timeline.filter((item, index) => {
          return (
            (index > timeline_index && item.type === MESSAGE_TYPE.INBOUND) ||
            (item.type === 'OUTBOUND' && item.user_id !== this.$root.user.id)
          );
        }).length > 0
      );
    },

    showMessagingForm: function () {
      return this.ticket.channel.type != null && this.ticket.channel.type != 'EMAIL' && this.ticket.status != 'CLOSED';
    },

    showReplyForm: function () {
      const isReplyFormVisible =
        this.ticketLoaded && this.userStore.hasPermission(PERMISSION.INBOX__CONVERSATIONS__REPLY);

      if (this.isInternalCommentRedesignEnabled) {
        return isReplyFormVisible || this.$root.user.role.name === 'observer';
      }

      return this.ticket.status !== 'CLOSED' && isReplyFormVisible && this.ticket.channel.type !== 'VOIP';
    },

    unbindEvents: function () {
      eventBus.$off('modals.ticket_reminder.added');
      eventBus.$off('ticket.current.reload');
      eventBus.$off('ticket.current.close');
      eventBus.$off('ticket.contact.' + this.ticket.contact_id + '.reload');
      eventBus.$off('replyTo');
      eventBus.$off('reactTo');
      eventBus.$off('appendMessage');
      eventBus.$off('ticket.bulk_selected');

      if (this.$refs.messageContainer) {
        this.$refs.messageContainer.removeEventListener('scroll', this.onScrollListener);
      }

      this.unbindPusherEvent(this.pusherTicketChannel, 'NEW_MESSAGE');
      this.unbindPusherEvent(this.pusherTicketChannel, 'UPDATE_MESSAGE');
      this.unbindPusherEvent(this.pusherTicketChannel, 'RELOAD_TICKET');
      this.unbindPusherEvent(this.pusherTicketChannel, 'ZIP_READY');
      this.unbindPusherEvent(this.pusherTicketChannel, 'ACTIVITY');
      this.unsubscribePusherChannel(this.pusherTicketChannel);

      // TODO L remove when using the new pusher channels
      this.unbindPusherEvent(this.legacyPusherTicketChannel, 'NEW_MESSAGE');
      this.unbindPusherEvent(this.legacyPusherTicketChannel, 'UPDATE_MESSAGE');
      this.unbindPusherEvent(this.legacyPusherTicketChannel, 'RELOAD_TICKET');
      this.unbindPusherEvent(this.legacyPusherTicketChannel, 'ZIP_READY');
      this.unbindPusherEvent(this.legacyPusherTicketChannel, 'ACTIVITY');
      this.unsubscribePusherChannel(this.legacyPusherTicketChannel);
    },

    bindEvents: function (id) {
      this.subscribeToPusherEvents(id);

      eventBus.$on('ticket.note.added', (data) => {
        this.appendMessage(data);
        this.$nextTick(() => {
          this.newMessage();
        });
      });

      eventBus.$on('modals.ticket_reminder.added', (data) => {
        this.ticket.reminder = data.reminder;
        this.$nextTick(this.scrollToBottom);
      });

      eventBus.$on('ticket.current.close', (ticket_id) => {
        this.closeTicket(ticket_id);
      });

      eventBus.$on('ticket.current.reload', (e = { focus: false, withMessages: false, emittedFromTicket: false }) => {
        if (this.isConsolidatedMessageEndpointEnabled && this.isTicketSentAndClosed && e.emittedFromTicket) {
          this.handleConsolidatedMessageEndpointReload(e);
        } else {
          ticketRepository
            .getTicket(this.ticket_id, false, false)
            .then((ticket) => {
              this.resetLoadingState();
              this.ticket = ticket;

              if (this.ticket.contact !== ticket.contact) {
                eventBus.$emit('contact.updated', {
                  old: this.ticket.contact,
                  new: ticket.contact,
                });
              }

              if (e && e.withMessages) {
                this.page = 0;
                this.messages = [];
                this.loadMoreMessages();
              }

              if (this.ticket.user_id === this.$root.user.id && ticket.status !== 'CLOSED') {
                this.focusOnForm();
              }
              this.ticket = ticket;
              this.$forceUpdate();

              eventBus.$emit('ticket.current.reload.done');
            })
            .catch((e) => {
              if (e.response.status === STATUS_CODE.FORBIDDEN) {
                eventBus.$emit('ticket.unauthorized', parseInt(this.ticket_id));
              }
              this.$router.push('/tickets');
              return;
            });
        }
      });

      eventBus.$on('replyTo', (data) => {
        this.replyTo = data;
        if (data) {
          this.focusOnForm();
        } else {
          this.blurForm();
        }
      });

      eventBus.$on('reactTo', (data) => {
        if (!this.reactLoading) {
          this.reactLoading = true;
          ticketRepository
            .reactToMessage(data.ticket_id, data.message_id, data.request_data)
            .then((res) => {
              const index = this.messages.findIndex((m) => m.id === res.data.message.id);
              if (index > -1) {
                const newMessages = [...this.messages];
                newMessages[index] = res.data.message;
                this.messages = newMessages;
              }
              this.reactLoading = false;
            })
            .catch(() => {
              console.error('Error while interacting with message');
            });
        }
      });

      if (!this.$refs.messageContainer) {
        return;
      }
      this.$refs.messageContainer.addEventListener('scroll', this.onScrollListener);
    },

    subscribeToPusherEvents(ticket_id) {
      this.pusherTicketChannel = this.subscribeToPusherChannel('private-ticket-' + ticket_id);
      this.bindPusherEvent(this.pusherTicketChannel, 'NEW_MESSAGE', (data) => this.processEventNewMessage(data));
      this.bindPusherEvent(this.pusherTicketChannel, 'UPDATE_MESSAGE', (data) => this.processEventUpdateMessage(data));
      this.bindPusherEvent(this.pusherTicketChannel, 'RELOAD_TICKET', (data) => this.processEventReloadTicket(data));
      this.bindPusherEvent(this.pusherTicketChannel, 'ZIP_READY', (data) => this.processEventZipReady(data));
      this.bindPusherEvent(this.pusherTicketChannel, 'ACTIVITY', (data) => this.processActivityEvent(data));

      // TODO L turn off after transition
      this.legacyPusherTicketChannel = PusherInstance.subscribe(CHANNEL_PREFIX + '@conversation-' + ticket_id);
      this.bindPusherEvent(this.legacyPusherTicketChannel, 'NEW_MESSAGE', (data) => this.processEventNewMessage(data));
      this.bindPusherEvent(this.legacyPusherTicketChannel, 'UPDATE_MESSAGE', (data) =>
        this.processEventUpdateMessage(data),
      );
      this.bindPusherEvent(this.legacyPusherTicketChannel, 'RELOAD_TICKET', (data) =>
        this.processEventReloadTicket(data),
      );
      this.bindPusherEvent(this.legacyPusherTicketChannel, 'ZIP_READY', (data) => this.processEventZipReady(data));
      this.bindPusherEvent(this.legacyPusherTicketChannel, 'ACTIVITY', (data) => this.processActivityEvent(data));
    },

    processEventNewMessage(data) {
      const isInboundNewMessagingFlow = this.isConsolidatedMessageEndpointEnabled && data.type === 'INBOUND';
      if (data.type == 'AUDIT') {
        this.audits.push(data);
        this.newMessage();
        return;
      }

      // from chatbot (simulate typing)
      if (data.type == 'OUTBOUND' && data.user_id == null && !this.isConsolidatedMessageEndpointEnabled) {
        setTimeout(() => {
          this.appendMessage(data);
          this.newMessage();
        }, 1000);
      } else if (!this.isConsolidatedMessageEndpointEnabled || isInboundNewMessagingFlow || data.type === 'NOTE') {
        if (this.ticket.channel.type === 'EMAIL' && data.type !== 'NOTE') {
          retryRequestIfResponseMatchesStatusCode(
            {
              method: 'GET',
              url: `${ENDPOINT.TICKETS}/${this.ticket.id}/email_messages/${data.id}`,
            },
            {
              statusCodes: [STATUS_CODE.NOT_FOUND],
            },
          ).then((res) => {
            this.appendMessage(res.data);
            this.newMessage();
          });
        } else {
          if (data.message_id) {
            ticketRepository.getMessage(this.ticket.id, data.message_id).then((res) => {
              this.messages.push(res.data);
              this.newMessage();
            });
          } else {
            this.appendMessage(data);
            this.newMessage();
          }
        }
      }
    },

    processEventReloadTicket() {
      eventBus.$emit('ticket.current.reload', { focus: true, emittedFromTicket: true });
    },

    processEventUpdateMessage(data) {
      const isOutboundNewMessagingFlow = this.isConsolidatedMessageEndpointEnabled && data.type === 'OUTBOUND';
      if (isOutboundNewMessagingFlow) {
        return;
      }

      if (data.message_id && this.timeline.filter((t) => t.id === data.message_id)) {
        ticketRepository.getMessage(this.ticket.id, data.message_id).then((res) => {
          const index = this.messages.findIndex((m) => m.id === res.data.id);
          if (index > -1) {
            this.messages.splice(index, 1, res.data);
          }
        });
      }
    },

    processEventZipReady(data) {
      if (typeof data.target !== 'undefined' && data.target !== this.$root.user.id) {
        return;
      }
      eventBus.$emit('ticket.zip_ready.' + data.message_id, {
        data,
      });
    },

    processActivityEvent(data) {
      let timestamp: number | null = null;
      switch (data.type) {
        case 'TYPING_STARTED':
          timestamp = new Date().getTime();
          this.clientComposing = {
            composing: true,
            body_type: 'TEXT',
            message: '',
            type: MESSAGE_TYPE.INBOUND,
            contact: this.ticket.contact,
            channel: this.ticket.channel,
            timestamp: timestamp,
          };
          setTimeout(() => {
            if (this.clientComposing && this.clientComposing.timestamp === timestamp) {
              this.clientComposing = null;
            }
          }, 5000);
          return;

        case 'TYPING_STOPPED':
          this.clientComposing = null;
          return;
      }
    },

    onScrollListener() {
      // if less than 200 px from bottom, hide new messages link
      if (
        this.$refs?.messageContainer?.scrollHeight -
          (this.$refs?.messageContainer?.offsetHeight + this.$refs?.messageContainer?.scrollTop) <
        200
      ) {
        this.lastSeenMessageId = _.get(_.last(this.timeline.filter((t) => t.type !== 'AUDIT')), 'id');
        this.showNewMessagesScrollLink = false;
      } else if (
        this.$refs?.messageContainer?.scrollHeight -
          (this.$refs?.messageContainer?.offsetHeight + this.$refs?.messageContainer?.scrollTop) >
        400
      ) {
        this.showNewMessagesScrollLink = true;
      }
    },

    resetLoadingState: function () {
      this.isReopening = this.isWorking;
      $('#TicketResultModal').modal('hide');
    },

    loadTicket: function (id) {
      if (!id) return;
      this.loading = true;
      this.page = 0;
      this.ticket_id = id;
      this.ticket.id = null;

      this.ticketsData.forEach((t) => {
        if (t.id === parseInt(id)) {
          this.prefillInfo(t);
        }
      });

      ticketRepository
        .getTicket(id, false)
        .then((ticket) => {
          this.$root.rememberPrivatePusherChannel('private-ticket-' + id, ticket.ws_auth);
          this.ticket = ticket;
          this.loading = false;
          this.audits = this.ticket.audits;
          this.$nextTick(() => {
            this.bindEvents(id);
            this.setAutoFocus();
          });
          if (!this.showReplyForm()) {
            this.draftLoaded = true;
          }
        })
        .catch(() => {
          this.$router.push('/tickets');
        });
    },

    appendMessage: function (message, scroll = false) {
      let isNew = true;

      const msg = _.find(this.messages, (m) => {
        return m.id == message.id;
      });

      if (msg != null) {
        msg.sending = false;
        isNew = false;
      } else {
        this.messages.push(message);
        if (message.attachments) {
          // Keep attachments in sidebar in sync
          this.ticket.attachments?.push?.(...message.attachments);
        }
        if (message.type != 'NOTE') {
          this.$nextTick(() => {
            $('.mail-message:last').addClass('fade-in');
          });
        }
        if (scroll) {
          this.scrollToBottom();
        }
      }

      if (!this.isConsolidatedMessageEndpointEnabled && isNew && message.type != 'AUDIT' && message.type != 'NOTE') {
        ticketRepository.readMessage(message.ticket_id, message.id);
      }

      // Set last seen message id to current message if it's from the user (message or audit)
      if (
        (message.user_id && message.user_id === this.$root.user.id) ||
        (message.agent && message.agent.id === this.$root.user.id)
      ) {
        this.lastSeenMessageId = message.id;
      }
    },

    setAutoFocus() {
      if (this.ticket.status === 'ASSIGNED' && this.ticket.channel.type !== 'EMAIL') {
        this.focusOnForm();
      }
    },

    newMessage() {
      if (!this.$refs.messageContainer) {
        return;
      }
      if (
        this.$refs.messageContainer.scrollHeight -
          (this.$refs.messageContainer.offsetHeight + this.$refs.messageContainer.scrollTop) >=
        200
      ) {
        this.showNewMessagesScrollLink = true;
        if (this.$root.hasFocus) {
          this.lastSeenMessageId = _.get(_.last(this.timeline), 'id');
        }
      } else {
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      }
    },

    scrollToBottom() {
      if (this.ticket.channel.type !== 'EMAIL' && this.$refs.messageContainer) {
        this.$refs.messageContainer.scrollTop = this.$refs.messageContainer.scrollHeight;
        return;
      }

      const lastMessage = this.messages.slice(-1)[0];

      if (!lastMessage) {
        return;
      }

      if (lastMessage.type === 'NOTE' && this.$refs.messageContainer) {
        this.$refs.messageContainer.scrollTop = this.$refs.messageContainer.scrollHeight;
        return;
      }

      this.$refs.messageContainer.scrollTop = $('.mail-message:not(.is-auto-reply):last')[0].offsetTop - 150;
    },

    loadMoreMessages: function (scroll) {
      this.page++;
      if (this.page > 1) {
        this.loadingMore = true;
      }
      ticketRepository
        .getMessages(this.ticket_id, this.page)
        .then((messages) => {
          this.loadingMore = false;
          messages = messages.data;
          this.hasMoreMessages = messages.links.next !== null;
          $.each(messages.data, (i, msg) => {
            if (msg.ticket_id === parseInt(this.ticket_id)) {
              this.messages.unshift(msg);
            }
          });
          this.$nextTick(() => {
            if (scroll) {
              scroll.loaded();
              if (!this.hasMoreMessages) {
                scroll.complete();
              }
            }
          });
          this.messagesLoaded = true;
        })
        .catch(() => {
          this.loadingMore = false;
        });
    },

    onAllLoaded() {
      this.showInitOverlay = false;
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.scrollToBottom();
        });
        this.showInitOverlay = false;
        eventBus.$emit('ticket-loaded', this.ticket);
      });
    },

    showAlert: function (message) {
      this.flashWarning(message);
    },

    closeTicket: function (ticket_id, force) {
      if (!this.ticketLoaded) {
        return;
      }

      if (!this.hasEmptyRequiredFields || force) {
        if (Object.keys(this.$root.ticketResults).length > 0) {
          eventBus.$emit('modals.ticket-result.open', {
            ticket_id: ticket_id,
            currentResult: this.ticket.results,
          });
        } else {
          if (this.ticket.id === ticket_id) {
            this.ticket.status = 'CLOSED';
          }

          ticketRepository.closeTicket(ticket_id);
        }
      } else {
        this.requiredModalOpen = true;
      }
    },

    reOpenTicket: function () {
      if (this.isReopening) {
        return;
      }

      this.isReopening = true;
      ticketRepository.reOpenTicket(this.ticket.id).then(() => {
        this.isReopening = false;
        this.focusOnEditor();
      });
    },

    ticketRequiresLabel: function () {
      return this.$root.companyProfile.settings.require_ticket_label && this.ticket.labels.length < 1;
    },

    ticketCanBeClosed: function () {
      return this.ticket.status !== STATUS_CLOSED && this.ticket.status !== STATUS_INVALID;
    },

    ticketCanBeReopened: function () {
      return this.ticket.status === STATUS_CLOSED;
    },

    async deleteTicket() {
      const shouldDeleteTicket = await this.$tConfirm('Are you sure you want to delete this ticket?', {
        delete: true,
        title: this.$t('general.are_you_sure'),
      });
      if (shouldDeleteTicket) {
        ticketRepository.deleteTicket(this.ticket.id);
        this.$router.push('/tickets');
      }
    },

    openMergeTicketModal: function () {
      eventBus.$emit('modals.merge-ticket.open', this.ticket);
    },

    forwardTicketModal: function () {
      eventBus.$emit('modals.forward-ticket.open', {
        ticket: this.ticket,
      });
    },

    openAddRelatedTicketModal: function () {
      eventBus.$emit('modals.add-related-ticket.open', {
        ticket: this.ticket,
      });
    },

    remindTicketModal: function () {
      eventBus.$emit('modals.remind-ticket.open', {
        ticket: this.ticket,
      });
    },

    markAsSpam: function () {
      if (this.isWorking) {
        return;
      }

      this.isWorking = true;
      ticketRepository.markAsSpam(this.ticket.id);
    },

    unMarkAsSpam: function () {
      if (this.isWorking) {
        return;
      }

      this.isWorking = true;
      ticketRepository.unMarkAsSpam(this.ticket.id);
    },

    copyUrl() {
      window.copyToClipboard('https://app.trengo.com/tickets/' + this.ticket.id);
    },

    focusOnForm: function () {
      this.$nextTick(() => {
        eventBus.$emit('reply_form.focus');
      });
    },

    blurForm: function () {
      eventBus.$emit('reply_form.blur');
    },

    exportAsPdf() {
      axios.get('/api/v2/tickets/' + this.ticket.id + '/pdf').then((res) => {
        const a = document.createElement('a');
        a.href = 'data:application/pdf;base64,' + res.data.pdf;
        a.download = 'Ticket_' + this.ticket.id + '.pdf';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    },

    ccUpdate(cc) {
      this.ticket.contact_cc = cc;
    },

    subjectUpdate(subject) {
      this.ticket.subject = subject;
    },

    printTicket() {
      const left = screen.width / 2 - 800 / 2;
      const top = screen.height / 2 - 600 / 2;

      const win = window.open(
        '',
        'Trengo',
        'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=800,height=600,top=' +
          top +
          ',left=' +
          left,
      );
      win.document.body.innerHTML = 'Loading...';
      axios.get('/api/v2/tickets/' + this.ticket.id + '/plain').then((result) => {
        win.document.body.innerHTML = sanitize(result.data.html, {
          FORBID_TAGS: ['style', 'svg', 'audio', 'video', 'math', 'meta', 'script'],
          FORBID_ATTR: ['class'],
        });
        setTimeout(() => {
          win.print();
          win.close();
        }, 200);
      });
    },

    async deleteMessage(message_id) {
      const shouldDeleteMessage = await this.$tConfirm('Are you sure you want to delete this item?', {
        title: this.$t('general.are_you_sure'),
        delete: true,
      });

      if (!shouldDeleteMessage) {
        return;
      }

      axios.delete('/api/v2/tickets/' + this.ticket.id + '/messages/' + message_id).then(() => {
        this.messages.splice(this.messages.map((item) => item.id).indexOf(message_id), 1);
      });
    },

    messageToNewTicket(message_id) {
      this.getTicketMessages().then((res) => {
        if (res.data.data.length > 1) {
          this.storeTicket().then((new_ticket_id) => {
            this.updateMessage(new_ticket_id, message_id).then(() => {
              eventBus.$emit('tickets.list.reload', {
                focus: true,
              });
              this.$router.push('/tickets/' + new_ticket_id);
            });
          });
        }
      });
    },

    storeTicket() {
      return axios
        .post('/api/v2/tickets', {
          channel_id: this.ticket.channel.id,
          contact_id: this.ticket.contact.id,
          subject: '',
        })
        .then((res) => {
          return res.data.id;
        });
    },

    getTicketMessages() {
      return axios.get('/api/v2/tickets/' + this.ticket.id + '/messages/');
    },

    updateMessage(new_ticket_id, message_id) {
      return axios.put('/api/v2/tickets/' + this.ticket.id + '/messages/' + message_id, {
        new_ticket_id: new_ticket_id,
        message_id: message_id,
      });
    },

    canAddReminder() {
      return (this.ticket.user_id == this.$root.user.id || this.ticket.user_id == null) && this.ticket.reminder == null;
    },

    cancelReminder() {
      axios.delete('/api/v2/tickets/' + this.ticket.id + '/reminders/' + this.ticket.reminder.id).then(() => {
        this.ticket.reminder = null;
      });
    },

    triggerStarred() {
      if (this.hasStarred) {
        axios.delete('/api/v2/tickets/' + this.ticket.id + '/favorited/0').then(() => {
          for (let i = 0; i < this.ticket.starred.length; i++) {
            if (this.ticket.starred[i].id === this.$root.user.id) {
              this.ticket.starred.splice(i);
            }
          }
        });
      } else {
        axios.post('/api/v2/tickets/' + this.ticket.id + '/favorited').then(() => {
          this.ticket.starred.push(this.$root.user);
          eventBus.$emit('ticket-favourite-set');
        });
      }
    },

    openSendAsModal() {
      if (!this.hasAccessToChannel) {
        return false;
      }
      eventBus.$emit('modals.send-as.open', {
        ticket: this.ticket,
      });
    },

    upgrade() {
      this.$router.push({ name: 'subscription' });
    },

    onClientComposing(text) {
      if ($.trim(text).length === 0) {
        this.clientComposing = null;
        return;
      }

      this.clientComposing = {
        composing: true,
        body_type: 'TEXT',
        message: text,
        type: MESSAGE_TYPE.INBOUND,
        contact: this.ticket.contact,
        channel: this.ticket.channel,
      };
    },

    isLatest(message) {
      const latest = last(this.timeline);
      return latest && message && latest.id === message.id;
    },

    isLatestInbound(message) {
      const latest = last(
        this.timeline.filter((m) => {
          return m.type === MESSAGE_TYPE.INBOUND;
        }),
      );
      return latest && message && latest.id === message.id;
    },

    toggleSidebar() {
      this.showSidebar = !this.showSidebar;
      this.$tStorage.setItem('sidebar_state', this.showSidebar ? 1 : 0);
      eventBus.$emit('ticket.sidebar.toggle', this.showSidebar);
    },

    resetState() {
      this.ticket_id = null;
      this.messages = [];
      this.hasMoreMessages = false;
      this.page = 0;
      this.isReopening = false;
      this.isWorking = false;
      this.loading = false;
      this.messagesLoaded = false;
      this.audits = [];
      this.clientComposing = null;
      this.replyTo = {};
      this.showNewMessagesScrollLink = false;
      this.lastFocusedMessageId = null;
      this.lastSeenMessageId = null;
      this.bulkSelectedTickets = 0;
      this.allLoaded = false;
      this.showInitOverlay = true;
      this.draftLoaded = false;
      this.loadingMore = false;
    },

    prefillInfo(t) {
      this.ticket.subject = t.subject;
      this.ticket.channel = t.channel;
      this.ticket.contact = t.contact;
      this.ticket.status = t.status;
      this.ticket.user_id = t.user_id;
      this.ticket.labels = t.labels || [];
      const user = this.$root.users.find((u) => t.user_id === u.id);
      if (user) {
        this.ticket.agent = user;
      } else {
        this.ticket.agent = null;
        this.ticket.user_id = null;
      }
    },

    getChannelName(type) {
      if (!type) {
        return;
      }
      if (type === 'WA_BUSINESS') {
        return 'WhatsApp';
      }
      return type.toLowerCase().charAt(0).toUpperCase() + type.toLowerCase().slice(1);
    },

    onSwipeRight() {
      router.back();
    },

    setComposerTab(tab) {
      this.activeTab = tab;
    },

    handleOnAssigned(event) {
      if (event.type === 'user') {
        this.ticket.status = event.status;
        this.ticket.agent = event.agent;
        this.ticket.user_id = event.user_id;
      }
      if (event.type === 'team') {
        this.ticket.status = event.status;
        this.ticket.team = event.team;
        this.ticket.team_id = event.team_id;
      }
    },

    focusOnEditor() {
      this.$refs.messageContainer.scrollTop = this.$refs.messageContainer.scrollHeight;

      setTimeout(() => {
        this.$refs.messageContainer.scrollTop = this.$refs.messageContainer.scrollHeight;
      }, 200);
    },

    updateContactName(name) {
      this.ticket.contact.full_name = name;
      eventBus.$emit('tickets.list.reload');
    },

    formatTime,

    handleAssignToSelf(value) {
      this.ticket.status = value.ticketStatus;
      this.ticket.agent = value.agent;
      this.ticket.user_id = value.userId;
    },

    handleAssignToTeam(value) {
      this.ticket.status = value.ticketStatus;
      this.ticket.team = value.team;
      this.ticket.team_id = value.teamId;
    },

    markPreviousTasks() {
      const prevNotes = this.messages.filter((m) => {
        return m.type === 'NOTE' && m.mentions !== null && m.id < this.message.id;
      });
      if (prevNotes.length) {
        prevNotes.map((note) => {
          note.mentions.map((mention) => {
            if (mention.user_id === this.$root.user.id) {
              mention.seen = true;
            }
          });
        });
      }
    },

    updateTicket(newValue) {
      this.ticket = newValue;
    },
    handleConsolidatedMessageEndpointClosedTicket(closedTicket: consolidatedSendResponseTicket) {
      this.closedTicket = closedTicket;
    },
    updateSendAndCloseTicketStatus(value: boolean) {
      this.isTicketSentAndClosed = value;
    },
    handleConsolidatedMessageEndpointReload(e) {
      if (this.ticket.contact !== this.closedTicket.contact) {
        eventBus.$emit('contact.updated', {
          old: this.ticket.contact,
          new: this.closedTicket.contact,
        });
      }

      if (e && e.withMessages) {
        this.page = 0;
        this.messages = [];
        this.loadMoreMessages();
      }

      if (this.ticket.user_id === this.$root.user.id && this.closedTicket.status !== 'CLOSED') {
        this.focusOnForm();
      }
      const { status, closed_at, updated_at } = this.closedTicket;
      Object.assign(this.ticket, { status, closed_at, updated_at });

      this.$forceUpdate();

      eventBus.$emit('ticket.current.reload.done');
    },

    async translateInboundMessage(
      payload: {
        message_id: number;
        ticket_id: number;
        prompt: string;
        isEmail?: boolean;
      },
      isReverting?: boolean,
    ) {
      const messageData = (this.timeline as FeedMessage[]).find((m) => m.id === payload.message_id);
      if (!messageData) return;

      const getStorageTranslations = () => {
        const item = sessionStorage.getItem('inbound_translations');
        if (!item) return;
        const translations = JSON.parse(item);

        return translations?.find(
          (translation: { id: string }) =>
            translation.id ===
            `${payload.ticket_id}_${payload.message_id}_${this.userStore?.user?.locale_code}_translated`,
        );
      };

      const message = getStorageTranslations();

      if (message) {
        const content = isReverting ? message?.original : message?.translated;
        messageData.message = content;

        if (payload.isEmail && messageData.email_message?.html) {
          messageData.email_message.html = content;
        }

        return;
      }

      const res = await this.inboundTranslationStore.fetchTranslation({
        message_id: payload.message_id,
        ticket_id: payload.ticket_id,
        prompt: payload.prompt,
        locale: this.userStore?.user?.locale_code as UserLocales,
      });

      if (res?.translated_prompt) {
        messageData.message = res?.translated_prompt;
      }

      if (payload.isEmail && messageData.email_message) {
        messageData.email_message.html = res?.translated_prompt;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.tab-active {
  background: white;
  padding: 3px 9px;
}

.newMessagesScrollLink {
  position: fixed;
  top: calc(56px * 2 + 1rem);
  padding: 0.5rem 1rem;
  border-radius: 10px;
  background-color: limegreen;
  color: #fff;
  z-index: 9999;
  left: 50%;
}

.ticket-bar-shadow {
  -webkit-box-shadow:
    0 1px 4px -2px rgba(74, 144, 226, 0.05),
    0 1px 9px -2px rgba(0, 0, 0, 0.11);
  box-shadow:
    0 1px 4px -2px rgba(74, 144, 226, 0.05),
    0 1px 9px -2px rgba(0, 0, 0, 0.11);
}

.ticket-bar .dropdown-menu {
  margin-top: 10px;
}

.ticket-labels-title {
  min-width: 140px;
  padding-bottom: 19px;
  flex: 1;
}

.slide-fade-enter-active {
  transition: all 0.2s ease;
}

.slide-fade-leave-active {
  transition: all 0.2s ease;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.starred {
  color: #f3c111;
}

.hover-ticket-nav-item:hover {
  /* black */
  color: inherit;
}

.bg-white.hover-ticket-nav-item:hover {
  background-color: #f1f1f1;
}

.ticket-option-icon {
  color: theme('colors.grey-600');
  margin-right: 0.7rem !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.twenty-four-hr-banner {
  @apply my-3 items-start;

  &__grey {
    @apply bg-grey-300;
  }
}

.twenty-four-hr-banner :deep(.t-inline-banner__action) {
  @apply self-start;
}

.twenty-four-hr-banner__message {
  @apply mb-0;
}

.twenty-four-hr-banner__btn {
  @apply flex items-center;
}

.action-link {
  @apply ml-2 inline;
}

@media (max-width: 1700px) {
  .ticket-labels-title {
    min-width: 105px;
  }
}

@media (max-width: 1650px) {
  .ticket-labels-title {
    min-width: 85px;
  }
}

@media (max-width: 1380px) {
  .ticket-labels-title {
    min-width: 70px;
  }
}

@media (max-width: 991px) {
  .ticket-subject {
    margin-top: 5px;
    min-width: 75px;
  }

  .ticket-labels {
    margin-bottom: 5px;
    padding-bottom: 0;
  }
}
</style>
