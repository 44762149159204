import { PERMISSION } from '@/Configs/Constants';

import legacyRoutes from './legacy';
import { ROUTE_NAME } from '../constants';

import type { RouteRecord } from '../types';

const routes: RouteRecord[] = [
  {
    path: '/admin/integrations',
    component: () => import(/* webpackChunkName: "IntegrationHub" */ '@/components/IntegrationHub/index.vue'),
    meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
    name: ROUTE_NAME.INTEGRATIONS,
    redirect: '/admin/integrations/all',
    children: [
      ...legacyRoutes,
      {
        path: '/admin/integrations/:filter',
        components: {
          default: () =>
            import(/* webpackChunkName: "IntegrationHubFilter" */ '@/components/IntegrationHub/Filter.vue'),
          Header: () =>
            import(
              /* webpackChunkName: "IntegrationHubFilterHeader */ '@/components/IntegrationHub/Elements/FilterHeader.vue'
            ),
        },
        meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
        name: 'integrationFilter',
      },
      {
        path: '/admin/integrations/:filter/:integrationId',
        components: {
          default: () =>
            import(/* webpackChunkName: "IntegrationDetails" */ '@/components/IntegrationHub/IntegrationDetails.vue'),
          Header: () =>
            import(
              /* webpackChunkName: "IntegrationDetailsHeader" */ '@/components/IntegrationHub/Elements/DetailsHeader.vue'
            ),
        },
        meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
        name: 'integrationDetails',
        children: [
          {
            path: '/admin/integrations/:filter/:integrationId/edit/:installationId',
            component: () =>
              import(
                /* webpackChunkName: "IntegrationHubDetailEditModal" */ '@/components/IntegrationHub/IntegrationInstallationEdit.vue'
              ),
            meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
            name: 'installationEdit',
          },
          {
            path: '/admin/integrations/:filter/:integrationId/rename/:installationId',
            component: () =>
              import(
                /* webpackChunkName: "IntegrationHubDetailRenameModal" */ '@/components/IntegrationHub/IntegrationInstallationRename.vue'
              ),
            meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
            name: 'installationRename',
          },
          {
            path: '/admin/integrations/:filter/:integrationId/uninstall/:installationId',
            component: () =>
              import(
                /* webpackChunkName: "IntegrationHubDetailUninstallModal" */ '@/components/IntegrationHub/IntegrationInstallationUninstall.vue'
              ),
            meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
            name: 'installationUninstall',
          },
        ],
      },
    ],
  },
];

export default routes;
