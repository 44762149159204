<template>
  <integration-loading v-if="isLoading" class="mt-4" />

  <t-inline-banner v-else-if="hasLoaded && hasError" type="error" class="mt-4">
    <template #icon>
      <cross-octagon-linear />
    </template>

    <p class="t-text-sm-emphasize m-0 p-0">
      {{ $t('integration_hub.sidebar_error_title') }}
    </p>

    <p class="t-text-sm m-0 p-0">
      {{ error ?? $t('integration_hub.sidebar_error_description') }}
    </p>
  </t-inline-banner>

  <div v-else-if="hasLoaded && contacts.length === 0" class="mt-4">
    <t-inline-banner type="default">
      <template #icon>
        <info-linear />
      </template>

      <p class="t-text-sm-emphasize m-0 p-0">
        {{ $t('integration_hub.sidebar_no_data_found', { name: installation.name }) }}
      </p>
    </t-inline-banner>
  </div>
  <div v-else class="mt-4">
    <div v-for="(contact, i) in contacts" :key="i" class="mb-4 rounded-lg border border-grey-300 bg-white px-2">
      <div v-if="contact.name">
        <a
          v-if="contact.contact_url"
          class="mb-3 cursor-pointer pt-3 text-base font-semibold text-leaf-500"
          :href="contact.contact_url"
          target="_blank"
        >
          {{ contact.name }}
        </a>
        <p v-else class="mb-3 pt-3 text-base font-semibold">
          {{ contact.name }}
        </p>
      </div>
      <div v-if="contact.company?.name">
        <span class="text-xs text-grey-600">{{ $t('integration_hub.sidebar_crm_company') }}</span>
        <p class="mb-3 text-sm font-semibold">{{ contact.company.name }}</p>
      </div>

      <div v-if="contact.phone_numbers.length > 0">
        <span class="text-xs text-grey-600">{{ $t('integration_hub.sidebar_crm_phone') }}</span>
        <div class="mb-3">
          <p v-for="(phone, phoneIdx) in contact.phone_numbers" :key="phoneIdx" class="my-0 text-sm font-semibold">
            {{ phone.country_code }}{{ phone.number }}
          </p>
        </div>
      </div>

      <div v-if="contact.email_addresses.length > 0">
        <span class="text-xs text-grey-600">{{ $t('integration_hub.sidebar_crm_email') }}</span>
        <div class="mb-3">
          <p v-for="(email, emailIdx) in contact.email_addresses" :key="emailIdx" class="my-0 text-sm font-semibold">
            {{ email.email }}
          </p>
        </div>
      </div>

      <div v-if="contact.contact_owner">
        <span class="text-xs text-grey-600">{{ $t('integration_hub.sidebar_crm_contact_owner') }}</span>
        <p class="mb-3 text-sm font-semibold">{{ contact.contact_owner }}</p>
      </div>

      <div v-if="contact.company?.number_of_employees">
        <span class="text-xs text-grey-600">{{ $t('integration_hub.sidebar_crm_company_size') }}</span>
        <p class="mb-3 text-sm font-semibold">{{ contact.company.number_of_employees }}</p>
      </div>

      <div v-if="contact?.after_sales_contact">
        <span class="text-xs text-grey-600">{{ $t('integration_hub.sidebar_crm_after_sales_contact') }}</span>
        <p class="mb-3 text-sm font-semibold">{{ contact.after_sales_contact }}</p>
      </div>

      <div v-if="contact?.contact_custom_id">
        <span class="text-xs text-grey-600">{{ $t('integration_hub.sidebar_crm_contact_custom_id') }}</span>
        <p class="mb-3 text-sm font-semibold">{{ contact.contact_custom_id }}</p>
      </div>

      <t-divider v-if="i !== Object.keys(contacts).length - 1" />
    </div>
  </div>
</template>

<script lang="ts">
import { InfoLinear, CrossOctagonLinear } from '@trengo/trengo-icons';
import { defineComponent } from 'vue';

import { fetchCrmContacts } from '@/api';

import IntegrationLoading from './IntegrationLoading.vue';

import type { CrmContact } from '@/api';
import type { InstalledIntegration } from '@/store/types/integrations';
import type Ticket from '@/types/tickets';
import type { AxiosError } from 'axios';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'IntegrationCrmContacts',
  components: {
    IntegrationLoading,
    InfoLinear,
    CrossOctagonLinear,
  },

  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    installation: {
      type: Object as PropType<InstalledIntegration>,
      default: () => ({}),
    },
    ticket: {
      type: Object as PropType<Ticket>,
      default: () => ({}),
    },
  },
  emits: ['set-error', 'set-company-id'],

  data() {
    return {
      error: '',
      isLoading: false,
      hasError: false,
      hasLoaded: false,
      contacts: [] as CrmContact[],
    };
  },

  watch: {
    selected: {
      immediate: true,
      handler(val) {
        if (val) {
          this.fetchCrmContacts();
        }
      },
    },
  },

  methods: {
    async fetchCrmContacts() {
      if (!this.ticket.contact.email && !this.ticket.contact.phone) {
        this.hasLoaded = true;
        return;
      }

      if (this.hasLoaded) {
        return;
      }

      try {
        this.isLoading = true;
        const { data: contacts } = await fetchCrmContacts(
          this.installation.installation_id,
          this.ticket.contact.email,
          this.ticket.contact.phone,
        );
        this.contacts = contacts;

        if (this.contacts[0]?.company?.id) {
          this.$emit('set-company-id', this.contacts[0].company.id);
        }

        this.$emit('set-error', 'contact', false);
      } catch (error: unknown) {
        const e = error as AxiosError;
        this.hasError = true;
        this.$emit('set-error', 'contact', true);
        console.error(e);
        this.error = e.response?.data?.message;
      } finally {
        this.hasLoaded = true;
        this.isLoading = false;
      }
    },
  },
});
</script>
