<template>
  <div class="w-full rounded-xl border-1 border-grey-300 bg-white pt-6">
    <div class="mx-6 mb-8 flex items-center gap-3">
      <t-thumbnail>
        <credit-card-outcome-linear />
      </t-thumbnail>
      <div class="flex-grow">
        <h5 class="t-text-h5 m-0 text-grey-800">
          {{ $t('transactions.breakdown_heading') }}
        </h5>
        <span class="text-sm text-grey-600">
          {{ $t('transactions.breakdown_subheading') }}
        </span>
      </div>
      <div>
        <t-action-menu v-model="isOpen" width="max-content">
          <template #trigger>
            <t-button btn-style="secondary" class="!h-8 !bg-grey-200">
              <import-linear size="1rem" class="inline" />
              Download .csv
            </t-button>
          </template>
          <template #content>
            <div class="flex max-h-72 flex-col overflow-y-scroll">
              <t-list-item
                v-for="item in downloadList"
                :key="item.month"
                class="mt-1"
                :label="item.label"
                size="sm"
                @click="download(item.month, item.year)"
              />
            </div>
          </template>
        </t-action-menu>
      </div>
    </div>
    <div class="overflow-x-scroll">
      <t-table-list alternate class="border-none">
        <t-table-list-row class="!h-10 !bg-white leading-none">
          <t-table-list-cell></t-table-list-cell>
          <t-table-list-cell v-for="(month, index) in tableData.cols" :key="month" class="p-2">
            <span
              v-if="index === tableData.cols.length - 1"
              v-tooltip="{
                content: $t('transactions.breakdown_current_month_tooltip'),
              }"
              class="cursor-default rounded-md bg-grey-200 px-2 py-1 text-sm font-semibold"
            >
              {{ capitalize(month) }}
            </span>
            <span v-else class="px-2 py-1 text-sm font-semibold">
              {{ capitalize(month) }}
            </span>
          </t-table-list-cell>
        </t-table-list-row>
        <t-table-list-row v-for="row in tableData.rows" :key="row.label" class="leading-none">
          <t-table-list-cell class="!px-6 py-5 text-sm font-semibold leading-none text-grey-800">
            {{ row.label }}
          </t-table-list-cell>
          <t-table-list-cell v-for="(value, i) in row.values" :key="i" class="p-5 px-4">
            <span class="text-sm text-grey-700">{{ value > 0 ? formatCurrencyWithCents(value) : '-' }}</span>
          </t-table-list-cell>
        </t-table-list-row>
        <t-table-list-row class="leading-none">
          <t-table-list-cell class="p-5 !px-6 text-sm font-semibold leading-none text-black">
            {{ $t('transactions.breakdown_total') }}
          </t-table-list-cell>
          <t-table-list-cell v-for="(value, i) in tableData.monthlyTotals" :key="i" class="p-4">
            <span class="text-sm text-grey-800">{{ value > 0 ? formatCurrencyWithCents(value) : '-' }}</span>
          </t-table-list-cell>
        </t-table-list-row>
      </t-table-list>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CreditCardOutcomeLinear, ImportLinear } from '@trengo/trengo-icons';
import { capitalize } from 'lodash';
import { computed, ref } from 'vue';

import { downloadTransactions } from '@/api';
import { MONTH, SHORT_MONTH_LIST } from '@/Configs/Constants';

import type { TransactionMonth } from '@/types';

type Props = {
  transactions: TransactionMonth[];
};

const props = defineProps<Props>();
const isOpen = ref(false);

const download = (month: string, year: string) => {
  const monthIndex = SHORT_MONTH_LIST.indexOf(month) + 1;

  downloadTransactions({ month: `${monthIndex}-${year}` }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `transactionsReport_${month}${year}.csv`);
    document.body.appendChild(link);
    link.click();
  });
};

const formatCurrencyWithCents = (cents: number) =>
  new Intl.NumberFormat('nl-NL', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(cents / 100);

const prepareTableData = (transactionMonths: TransactionMonth[]) => {
  const cols = transactionMonths.map(({ month }) => month);
  const amountsByCategory = transactionMonths
    .map(({ categories }) => categories)
    .flat()
    .reduce((acc, { label, amount }) => {
      if (!acc[label]) {
        acc[label] = [];
      }
      acc[label].push(amount);
      return acc;
    }, {});
  const monthlyTotals = transactionMonths.reduce((totals, { categories }, index) => {
    const monthTotal = categories.reduce((acc, { amount }) => acc + amount, 0);
    totals[index] = monthTotal;
    return totals;
  }, [] as number[]);
  const rows = Object.entries(amountsByCategory).map(([label, values]) => ({ label, values }));
  return { cols, rows, monthlyTotals };
};

const prepareDownloadList = (transactionMonths: TransactionMonth[]) => {
  return transactionMonths.map((transactionMonth) => ({
    label: `${MONTH[transactionMonth.month.toUpperCase()]} ${transactionMonth.year}`,
    month: transactionMonth.month,
    year: transactionMonth.year,
  }));
};

const tableData = computed(() => prepareTableData(props.transactions));

const downloadList = computed(() => prepareDownloadList(props.transactions));
</script>

<style scoped lang="scss">
.t-action-menu-card {
  padding-right: 1px !important;
  height: 5px;
}

.overflow-x-scroll::-webkit-scrollbar {
  height: 5px;
}

.overflow-y-scroll::-webkit-scrollbar {
  width: 5px;
}
</style>
./constants
