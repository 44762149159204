<script type="text/babel">
import _ from 'lodash';

import eventBus from '@/eventBus';

import contactRepository from '../repositories/Contact';
import ticketRepository from '../repositories/Ticket';

export default {
  data: function () {
    return {
      ticket: null,
      tickets: [],
      form: {
        ticket_id: null,
      },
      loaded: false,
    };
  },

  computed: {
    formattedDate() {
      return moment(this.ticket.created_at).format('DD-MM-YYYY\, HH:mm');
    },
  },

  mounted: function () {
    eventBus.$off('modals.merge-ticket.open');
    eventBus.$on('modals.merge-ticket.open', (ticket) => {
      this.loaded = false;
      this.tickets = [];
      this.ticket = ticket;
      this.form.ticket_id = null;
      this.fetchTickets(ticket);
    });
  },

  methods: {
    fetchTickets: function (ticket) {
      const channelIdsWithSameChannelType = this.$root.channels
        .filter((channel) => channel.type === ticket.channel.type)
        .map((channel) => channel.id);

      contactRepository.fetchTickets(ticket['contact_id'], channelIdsWithSameChannelType).then((result) => {
        this.loaded = true;
        this.tickets = _.filter(result.data, (t) => {
          return t.channel.type === ticket.channel.type && t.id !== ticket.id;
        });

        if (this.tickets.length === 0) {
          return;
        }

        this.form.ticket_id = this.tickets[0].id;
      });

      $('#MergeTicketModal').modal();
    },

    merge: function () {
      if (this.form.ticket_id == null) {
        return;
      }

      ticketRepository.mergeTicket(this.ticket.id, this.form.ticket_id).then(() => {
        $('#MergeTicketModal').modal('hide');

        eventBus.$emit('ticket.current.reload', {
          focus: true,
          withMessages: true,
        });
      });
    },
  },
};
</script>

<template>
  <div id="MergeTicketModal" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('tickets.merge_ticket') }}</h5>
          <button type="button" class="close modal-close-btn" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div v-if="loaded" class="modal-body">
          <p>{{ $t('tickets.merge_ticket_intro') }}</p>
          <div class="form-group">
            <select v-model="form.ticket_id" class="form-control">
              <option v-for="ticket in tickets" :value="ticket.id">
                {{ formattedDate }} - #{{ ticket.id }} - {{ ticket.subject }}
              </option>
            </select>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn success rounded-full"
            :disabled="!loaded || this.form.ticket_id == null"
            @click="merge"
          >
            {{ $t('tickets.merge_ticket') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
